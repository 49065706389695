import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSnackbar } from 'notistack'
import axios, { AxiosError } from 'axios'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { useInterval, useEffectOnce } from 'usehooks-ts'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { AppDispatch, RootState } from '../redux/root'
import API, { FUNCTIONS_NAME } from '../services/api'
import { getIdToken } from '../services/aws'
import { StaticLambdaClient } from '../lib/lambda'
import { getLambdaData, upsertLambdaData } from './localstorage'

export interface ResponseWithErrorMessage {
  message: string
  response: {
    data: {
      message?: string
    }
  }
}

export type ErrorType = Error | AxiosError

export const getErrorMessage = (err: Error | AxiosError | unknown): string => {
  let message = (err as ErrorType).message

  if (axios.isAxiosError(err)) {
    const error = err as ResponseWithErrorMessage
    message = error.response?.data?.message || message
  }

  return message
}

export function useHandleError () {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  // eslint-disable-next-line
  const ref = useRef<(err: Error | AxiosError) => void>((err: ErrorType) => {
    let message = err.message

    if (axios.isAxiosError(err)) {
      const error = err as ResponseWithErrorMessage
      message = error.response?.data?.message || message
    }

    enqueueSnackbar(message, {
      variant: 'error',
      style: { whiteSpace: 'pre-line' }
    })
    console.error(err)
  })

  useEffect(() => {
    const handleError = (err: Error | AxiosError) => {
      let message = err.message

      if (axios.isAxiosError(err)) {
        const error = err as ResponseWithErrorMessage
        message = error.response?.data?.message || message
      }

      enqueueSnackbar(message, {
        variant: 'error',
        style: { whiteSpace: 'pre-line' }
      })
      console.error(err)
    }

    ref.current = handleError
  }, [enqueueSnackbar])

  return { handleError: ref.current, enqueueSnackbar, closeSnackbar }
}

type LambdaKeys = keyof typeof FUNCTIONS_NAME
type LambdaValuesTypes = typeof FUNCTIONS_NAME[LambdaKeys]

/*
  Foi optado por utilizar o localstorage para armazenar os dados de cada lambda para evitar
  que o usuário abra várias abas e acabe fazendo várias requisições desnecessárias. Por este motivo o redux não foi utilizado,
  uma vez que ele não é compartilhado entre as abas.
*/
export const useLambdaWarmup = <Payload, >(lambdaName: LambdaValuesTypes, payload: Payload, pollingInterval?: number, requestInterval?: number) => {
  const internalPollingInterval = useMemo(() => {
    const defaultInterval = 10 * 1000 // 10 seconds
    return pollingInterval ?? defaultInterval
  }, [pollingInterval])

  const internalRequestInterval = useMemo(() => {
    const defaultInterval = 5 * 60 * 1000 // 5 minutes
    return requestInterval ?? defaultInterval
  }, [requestInterval])

  const functionHandler = useCallback(async () => {
    const lambdaData = getLambdaData(lambdaName)
    const lastExecutionDate = lambdaData?.lastExecutionDate
    const status = lambdaData?.status

    const now = new Date()
    const alreadyWarmUp = lastExecutionDate && (now.getTime() - lastExecutionDate.getTime()) < internalRequestInterval
    if (alreadyWarmUp || status === 'loading') {
      return
    }

    try {
      upsertLambdaData({ name: lambdaName, status: 'loading', lastExecutionDate })
      const idToken = await getIdToken()
      const lambdaClient = await StaticLambdaClient.getInstance({ idToken })
      await lambdaClient.invokeFunction({
        functionName: lambdaName,
        body: payload
      })
      upsertLambdaData({ name: lambdaName, status: 'loaded', lastExecutionDate: new Date() })
    } catch (err) {
      upsertLambdaData({ name: lambdaName, status: status ?? 'none', lastExecutionDate })
      console.error(err)
    }
  }, [lambdaName, payload, internalRequestInterval])

  useEffectOnce(() => { functionHandler() })
  useInterval(functionHandler, internalPollingInterval)
}

export const useDocumentList = (type: 'matricula' | 'matriculaV2' | 'contrato' | 'faturamento' | 'peticaoInicial') => {
  const updatedAtRef = useRef<null | Date>(null)
  const params = useParams()

  const apiRoute = {
    matricula: API.matricula.list,
    matriculaV2: API.matriculaV2.list,
    contrato: API.contrato.list,
    faturamento: API.faturamento.list,
    peticaoInicial: API.peticaoInicial.list
  }

  const wrappedApiRoute = async () => {
    const response = await apiRoute[type]()
    updatedAtRef.current = new Date()
    return response
  }

  const output = useSWR(Object.keys(params).length === 0 ? `/${type}` : null, wrappedApiRoute, {
    refreshInterval: 20 * 1000, // 20 seconds
    revalidateOnFocus: false
  })

  return { ...output, updatedAt: updatedAtRef.current }
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
