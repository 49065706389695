import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { AmplifyProvider, translations } from '@aws-amplify/ui-react'
import { Auth, Amplify, I18n, Hub } from 'aws-amplify'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { SnackbarProvider, SnackbarKey } from 'notistack'
import { Button } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import enLocale from 'date-fns/locale/en-US'

import App from './App'
import reportWebVitals from './reportWebVitals'

import { store, persistor } from './redux/root'

import theme from './config/theme'
import { amplifyConfig } from './config/amplify'
import { AWS_COGNITO_AUTH_DOMAIN, HEAD_TITLE, SCOPE, SCOPE_GROUP } from './config/env'

import LoginPage from './pages/login'
import ProcessingPage from './pages/processing'
import UnauthorizedAccess from './pages/unauthorized-access'

import { Header } from './components'

const settings: { [keys: string]: unknown } = {
  aws_cognito_region: amplifyConfig.awsCognitoRegion,
  aws_user_pools_id: amplifyConfig.awsUserPoolsId,
  aws_user_pools_web_client_id: amplifyConfig.awsUserPoolsWebClientId,
  oauth: {
    domain: AWS_COGNITO_AUTH_DOMAIN,
    scope: [
      'email',
      'profile',
      'openid'
    ],
    redirectSignIn: window.location.origin,
    redirectSignOut: window.location.origin,
    clientId: amplifyConfig.awsUserPoolsId,
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
}

Amplify.configure(settings)
I18n.putVocabularies(translations)
I18n.setLanguage('pt-br')

interface IUser {
  username: string
  cognitoGroups: string[]
}

const AuthenticationWrapper = () => {
  const [loading, setLoading] = React.useState(true)
  const [currentUser, setCurrentUser] = React.useState<undefined | null | IUser>()

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        setLoading(false)
        setCurrentUser({
          username: currentUser.username,
          cognitoGroups: currentUser.signInUserSession.idToken.payload['cognito:groups'] ?? []
        })
      })
      .catch(() => setLoading(false))

    const unsubscribe = Hub.listen('auth', data => {
      if (data.payload.event === 'signIn') {
        setCurrentUser({
          username: data.payload.data.username,
          cognitoGroups: data.payload.data.signInUserSession.idToken.payload['cognito:groups'] ?? []
        })
      } else if (data.payload.event === 'signOut') {
        setCurrentUser(undefined)
      }
    }
    )

    return unsubscribe
  }, [])

  if (loading) {
    return <ProcessingPage />
  }

  if (!currentUser) {
    return <LoginPage />
  }

  const isNotAuthorized = currentUser.cognitoGroups.includes(SCOPE_GROUP) === false

  if (isNotAuthorized) {
    return <UnauthorizedAccess />
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  )
}

const AppWrapper = () => {
  const notistackRef = React.createRef<SnackbarProvider>()

  const onCloseNotification = (key: SnackbarKey) => () => {
    if (!notistackRef.current) {
      return
    }

    notistackRef.current.closeSnackbar(key)
  }

  return (
    <>
      <Header>
        <title>{HEAD_TITLE}: Smart Assistant</title>
        <link rel='icon' href={`/favicon-${SCOPE.toLowerCase()}.ico`} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='theme-color' content='#000000' />
        <meta name='description' content={`Acompanhe tudo pelo ${HEAD_TITLE} Dashboard`} />
        <link rel='apple-touch-icon' href={`/favicon-${HEAD_TITLE.toLowerCase()}.ico`} />
      </Header>
      <SnackbarProvider
        preventDuplicate
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        ref={notistackRef}
        action={(key: SnackbarKey) => (
          <Button onClick={onCloseNotification(key)} sx={{ color: '#fff' }}>Fechar</Button>
        )}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
            <CssBaseline />
            <BrowserRouter>
              <AuthenticationWrapper />
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root')!)

root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <AppWrapper />
    </AmplifyProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
