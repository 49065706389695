import { IGetHistoryItem } from '../services/api/matricula'
import { normalize } from '../utils/formatter'

// WARNING: modificado temporariamente porque o GPT não está encontrando os alertas
const ALIENADO_OPTIONS = [
  'alienacao',
  'hipoteca',
  'arremata',
  'penhora',
  'indisponibilidade',
  'usufruto'
]

export const isAlienadoTransactionType = (tipoTransacao?: string) => {
  if (!tipoTransacao) {
    return undefined
  }

  const normalized = normalize(tipoTransacao.toLowerCase())

  return ALIENADO_OPTIONS.find(option => normalized.includes(option))
}

export const isAlienado = (tipoTransacao?: string) => {
  if (!tipoTransacao) {
    return undefined
  }

  const normalized = normalize(tipoTransacao.toLowerCase())

  return ALIENADO_OPTIONS.some(option => normalized.includes(option))
}

export const getCurrentOwner = (history: Array<IGetHistoryItem>) => {
  const options = ['compra', 'venda', 'doacao', 'transmissao', 'aquisicao', 'sucessao']

  let lastOwner: string | undefined
  for (let i = history.length - 1; i >= 0; i--) {
    const item = history[i]
    const normalizedTransaction = normalize(item.tipoTransacao?.toLowerCase() || '')

    if (!lastOwner) {
      // console.log('Temp owner', i, item.date)
      lastOwner = item.adquirente?.[0]?.nome
    }

    const isInOptions = options.some(option => normalizedTransaction.includes(option))
    if (isInOptions) {
      // console.log('Index', i, item.date)
      return item.adquirente?.[0]?.nome
    }
  }

  return lastOwner
}

// WARNING: modificado temporariamente porque o GPT não está encontrando os alertas
export const formatAlerts = (alerts: { [key: string]: boolean }) => {
  const keys = Object.keys(alerts) as string[]

  const dict: Record<string, { title: string, description: string }> = {
    alienacao: {
      title: 'Alienação',
      description: ''
    },
    hipoteca: {
      title: 'Hipoteca',
      description: ''
    },
    arremata: {
      title: 'Arrematação',
      description: ''
    },
    penhora: {
      title: 'Penhora',
      description: ''
    },
    indisponibilidade: {
      title: 'Indisponibilidade',
      description: 'Quando por algum motivo legal o imóvel encontra-se indisponível para venda.'
    },
    usufruto: {
      title: 'Usufruto',
      description: 'É o direito do usufrutuário a utilizar o bem imóvel.'
    }
  }

  return keys.map(key => {
    return { ...dict[key], active: alerts[key] }
  })

  // WARNING: modificado temporariamente porque o GPT não está encontrando os alertas
  // type Key = (keyof IGetHistoryAlertas)

  // const keys = Object.keys(alerts) as Key[]

  // const dict: Record<Key, { title: string, description: string }> = {
  //   areaContaminada: {
  //     title: 'Área contaminada',
  //     description: 'Quando existe informação na matricula que o terreno onde o imóvel está localizado, está contaminado.'
  //   },
  //   areaDeRecreio: {
  //     title: 'Área de recreio',
  //     description: 'Quando o imóvel possui direito a outras áreas.'
  //   },
  //   concessaoDePatrimonioDeAfetacaoOuRegimeDeAfetacao: {
  //     title: 'Concessão de patrimônio de afetação ou regime de afetação',
  //     description: 'É o mesmo de Regime de Afetação: É uma garantia que o empreendimento será concluído e entregue as unidades.'
  //   },
  //   encargos: {
  //     title: 'Encargos',
  //     description: 'Obrigações a serem cumpridas na venda ou aquisição do imóvel.'
  //   },
  //   imovelArrematadoPorLeilao: {
  //     title: 'Imóvel arrematado por leilão',
  //     description: 'É a venda judicial quanto feita por leilão.'
  //   },
  //   imovelPenhorado: {
  //     title: 'Imóvel penhorado',
  //     description: 'É uma garantia que o devedor pague a dívida.'
  //   },
  //   impenhorabilidade: {
  //     title: 'Impenhorabilidade',
  //     description: 'O imóvel não poderá ser penhorado.'
  //   },
  //   inalienabilidade: {
  //     title: 'Inalienabilidade',
  //     description: 'O imóvel gravado com esta cláusula não pode ser alienado nem penhorado.'
  //   },
  //   incomunicabilidade: {
  //     title: 'Incomunicabilidade',
  //     description: 'O bem não se comunica por ocasião do casamento, independentemente do regime de casamento.'
  //   },
  //   indisponibilidade: {
  //     title: 'Indisponibilidade',
  //     description: 'Quando por algum motivo legal o imóvel encontra-se indisponível para venda.'
  //   },
  //   leiloesNegativos: {
  //     title: 'Leilões negativos',
  //     description: 'Quando o imóvel foi para leilão e não foi arrematado.'
  //   },
  //   levantamentoDeSequestro: {
  //     title: 'Levantamento de sequestro',
  //     description: 'Sequestro: é uma garantia para uma execução. Levantamento de Sequestro é o cancelamento do mesmo.'
  //   },
  //   regimeDeAforamento: {
  //     title: 'Regime de aforamento',
  //     description: 'O imóvel é foreiro a União, no qual o proprietário só terá direito ao domínio util.'
  //   },
  //   registroTorrens: {
  //     title: 'Registro Torrens',
  //     description: 'Tem finalidade oferecer ao proprietário de imóvel rural a presunção absoluta de domínio.'
  //   },
  //   remissaoDeForo: {
  //     title: 'Remissão de foro',
  //     description: 'É o ato pelo qual o proprietário de um imóvel foreiro adquire também o domínio direto, passando a ser pleno proprietário do imóvel.'
  //   },
  //   renunciaDeUsufruto: {
  //     title: 'Renúncia de usufruto',
  //     description: 'Quando o usufrutuário renuncia seu direito de uso do imóvel, no caso o cancelamento do usufruto não se dá somente com o falecimento do usufrutuário.'
  //   },
  //   servidaoDePassagem: {
  //     title: 'Servidão de passagem',
  //     description: 'Quando por algum motivo consta na matricula informação que no imóvel possui uma autorização de passagem, como por exemplo Dutos de energia.'
  //   },
  //   tombamentoDeBemImovel: {
  //     title: 'Tombamento de bem imóvel',
  //     description: 'Ato de preservar o bem imóvel, por meio de aplicação de legislação especifica.'
  //   },
  //   usufruto: {
  //     title: 'Usufruto',
  //     description: 'É o direito do usufrutuário a utilizar o bem imóvel.'
  //   }
  // }

  // return keys.map(key => {
  //   return { ...dict[key], active: alerts[key] }
  // })
}
