import { getIdToken, getAccessToken } from '../aws'
import axios from 'axios'

import { StaticS3Client } from '../../lib/s3'

import { BUCKET_NAME } from '../../config/consts'
import { API_BASE_URL, STAGE } from '../../config/env'

import { hashObject } from '../../utils/crypto'
import { capitalize } from '../../utils/formatter'

export const FUNCTIONS_NAME = {
  PROCESS_CONTRACT: `SmartAssistant-PeticaoInicial-Function-${capitalize(STAGE)}`
} as const

export interface IGetHistory {
  adicionalDePericulosidade: boolean
  adicionalInsalubriadade: boolean
  adicionalNoturno: boolean
  avisoPrevio: boolean
  contribuicaoAssistencial: boolean
  danoMoral: boolean
  decimoTerceiro: boolean
  diferencasSalariaisOuEquiparacaoSalarial: boolean
  ferias: boolean
  fgts: boolean
  honorariosAdvocaticios: boolean
  horasExtras: boolean
  indenizacaoPorDanoEmergente: boolean
  intervaloExtrajornada: boolean
  intervaloIntrajornada: boolean
  justicaGratuita: boolean
  multaDo467: boolean
  multaDo477: boolean
  multaNormativa: boolean
  outros: boolean
  reconhecimentoDeVinculo: boolean
  reflexosDasParcelasSalariais: boolean
  reflexosDeSalariosOficiososEInformais: boolean
  saldoDeSalario: boolean
}

export interface IFileItem {
  name: string,
  content: string,
  type: string
  _raw: File
}

export interface GetHistoryProps {
  file: IFileItem
}

export const getHistory = async ({ file }: GetHistoryProps): Promise<void> => {
  const idToken = await getIdToken()
  const accessToken = await getAccessToken()

  const s3Client = await StaticS3Client.getInstance({ idToken })
  const id = hashObject(file.content)
  const fname = `${id}`
  await s3Client.writeFile({
    bucket: BUCKET_NAME,
    key: `stage=${STAGE}/resource=peticao_inicial/type=raw/${fname}`,
    data: file._raw,
    contentType: file.type
  })

  await axios.post(`${API_BASE_URL}/start`, {
    fname: id,
    original_fname: file.name,
    document_type: 'peticao_inicial'
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

interface Response {
  adicionalDePericulosidade: boolean
  adicionalInsalubriadade: boolean
  adicionalNoturno: boolean
  avisoPrevio: boolean
  contribuicaoAssistencial: boolean
  danoMoral: boolean
  decimoTerceiro: boolean
  diferencasSalariaisOuEquiparacaoSalarial: boolean
  ferias: boolean
  fgts: boolean
  honorariosAdvocaticios: boolean
  horasExtras: boolean
  indenizacaoPorDanoEmergente: boolean
  intervaloExtrajornada: boolean
  intervaloIntrajornada: boolean
  justicaGratuita: boolean
  multaDo467: boolean
  multaDo477: boolean
  multaNormativa: boolean
  outros: boolean
  reconhecimentoDeVinculo: boolean
  reflexosDasParcelasSalariais: boolean
  reflexosDeSalariosOficiososEInformais: boolean
  saldoDeSalario: boolean
}

export const parsePeticaoInicial = (peticaoInicial: Response): IGetHistory => {
  return peticaoInicial
}
