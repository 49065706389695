import React from 'react'
import { Dialog as DialogMaterial, DialogContent, DialogTitle, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export interface DialogProps {
  open: boolean
  fullWidth?: boolean
  fullScreen?: boolean
  bodyRef?: React.RefObject<HTMLDivElement>
  title: string
  children: JSX.Element
  onClose: () => void
}

export default function Dialog (props: DialogProps) {
  const { children, title, onClose, open, fullWidth, fullScreen, bodyRef } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <DialogMaterial
      onClose={handleClose}
      open={open}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
    >
      <DialogTitle sx={{ m: 0, p: 2, pr: 8 }}>
        {title}
        {onClose
          ? (
            <IconButton
              aria-label='close'
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
            )
          : null}
      </DialogTitle>
      <DialogContent ref={bodyRef}>
        {children}
      </DialogContent>
    </DialogMaterial>
  )
}
