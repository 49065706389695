import React from 'react'
import { Box, Tooltip, Typography, TypographyProps } from '@mui/material'

import HelpIcon from '@mui/icons-material/Help'

export interface FieldProps {
  label: string | React.ReactNode,
  value?: string | null,
  withHelp?: boolean
  helpText?: string
  children?: React.ReactNode
  valueSx?: TypographyProps['sx']
  labelSx?: TypographyProps['sx']
}
export default function Field ({ label, value, children, valueSx, labelSx, withHelp, helpText }: FieldProps) {
  return (
    <Box>
      <Typography component='p' variant='caption' fontWeight='bold' textTransform='uppercase' align='left' color='secondary' sx={labelSx}>{label}{withHelp && <Tooltip title={helpText}><HelpIcon sx={{ ml: 2 }} fontSize='small' /></Tooltip>}</Typography>

      {children || <Typography component='p' variant='body1' align='left' color='black' sx={valueSx}>{value}</Typography>}
    </Box>
  )
}
