import React from 'react'
import { Typography, Box, Link } from '@mui/material'

export default function ErrorLoadPage (): JSX.Element {
  return (
    <Box sx={{ display: 'flex', height: '100%', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <Typography align='center' component='h2' variant='h4' >
          Ops! Não foi possível carregar a página
        </Typography>
        <Typography align='center' component='h2' variant='h6' sx={{ marginTop: 2 }} >
          <Link href='#' component='a' onClick={() => window.location.reload()}>
            Clique aqui
          </Link>
          {' '}para recarregar a página
        </Typography>
      </Box>
    </Box>
  )
}
