import React, { ReactNode } from 'react'

import LoadingContainer from './LoadingContainer'

export default function LazyLoadPage ({ children }: { children: ReactNode }): JSX.Element {
  return (
    <React.Suspense fallback={<LoadingContainer />}>
      {children}
    </React.Suspense>
  )
}
