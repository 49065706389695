import React, { CSSProperties, useCallback } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import {
  GoogleMap,
  useJsApiLoader
} from '@react-google-maps/api'

import { GOOGLE_AUTHORIZATION_KEY } from '../config/env'

import LoadingContainer from './Lazy/LoadingContainer'

import { IGetHistoryIntersectMatricula } from '../services/api'

const AREA_TYPE = {
  IMOVEL: {
    id: 'imovel',
    label: 'Área do Imóvel',
    color: '#000000'
  },
  RESERVA: {
    id: 'reserva',
    label: 'Reserva Legal',
    color: '#027639'
  },
  PRESERVACAO: {
    id: 'preservacao',
    label: 'Área de Preservação Permanente',
    color: '#cc0000'
  },
  TERRAS_INDIGENAS_E_ALDEIAS: {
    id: 'terrasIndigenasEAldeias',
    label: 'Terras Indígenas e Aldeias',
    color: '#df9dc0'
  },
  UNIDADES_DE_CONSERVACAO: {
    id: 'unidadesDeConservacao',
    label: 'Unidades de Conservação',
    color: '#ff4940'
  },
  AREA_DESMATAMENTO: {
    id: 'areaDesmatamento',
    label: 'Área de desmatamento',
    color: '#ff8246'
  }
} as const

export interface DynamicGoogleMapsProps {
  geoJson?: Object,
  intersectAreaPreservacaoPermanente?: IGetHistoryIntersectMatricula['data']
  intersectReservaLegal?: IGetHistoryIntersectMatricula['data']
  intersectTerrasIndigenasEAldeias?: IGetHistoryIntersectMatricula['data']
  intersectAreaDesmatamento?: IGetHistoryIntersectMatricula['data']
  intersectUnidadesDeConservacao?: IGetHistoryIntersectMatricula['data']
  mapContainerStyle?: CSSProperties
  showLegend?: boolean
}

const DynamicGoogleMaps = React.memo(({
  geoJson: geometry,
  mapContainerStyle,
  intersectAreaPreservacaoPermanente,
  intersectReservaLegal,
  intersectTerrasIndigenasEAldeias,
  intersectAreaDesmatamento,
  intersectUnidadesDeConservacao,
  showLegend
}: DynamicGoogleMapsProps) => {
  const onLoad = useCallback((map: google.maps.Map) => {
    if (!geometry) {
      return
    }

    const geoJson = { type: 'Feature', geometry, properties: { name: AREA_TYPE.IMOVEL.id } }

    intersectAreaPreservacaoPermanente?.forEach((item) => {
      const geoJson = {
        type: 'Feature',
        geometry: item.geoJson,
        properties: {
          name: AREA_TYPE.PRESERVACAO.id
        }
      }
      map.data.addGeoJson(geoJson)
    })

    intersectReservaLegal?.forEach((item) => {
      const geoJson = {
        type: 'Feature',
        geometry: item.geoJson,
        properties: {
          name: AREA_TYPE.RESERVA.id
        }
      }
      map.data.addGeoJson(geoJson)
    })

    intersectTerrasIndigenasEAldeias?.forEach((item) => {
      const geoJson = {
        type: 'Feature',
        geometry: item.geoJson,
        properties: {
          name: AREA_TYPE.TERRAS_INDIGENAS_E_ALDEIAS.id
        }
      }
      map.data.addGeoJson(geoJson)
    })

    intersectAreaDesmatamento?.forEach((item) => {
      const geoJson = {
        type: 'Feature',
        geometry: item.geoJson,
        properties: {
          name: AREA_TYPE.AREA_DESMATAMENTO.id
        }
      }
      map.data.addGeoJson(geoJson)
    })

    intersectUnidadesDeConservacao?.forEach((item) => {
      const geoJson = {
        type: 'Feature',
        geometry: item.geoJson,
        properties: {
          name: AREA_TYPE.UNIDADES_DE_CONSERVACAO.id
        }
      }
      map.data.addGeoJson(geoJson)
    })

    map.data.addGeoJson(geoJson)
    const bounds = new google.maps.LatLngBounds()

    map.data.forEach(function (feature) {
      const geo = feature.getGeometry()
      if (!geo) {
        return
      }

      geo.forEachLatLng(function (LatLng) {
        bounds.extend(LatLng)
      })
    })

    map.fitBounds(bounds)
    map.setZoom(10)

    map.data.setStyle((feature) => {
      type IDs = typeof AREA_TYPE[keyof typeof AREA_TYPE]['id']
      const name = feature.getProperty('name') as IDs

      const color = Object.values(AREA_TYPE).find(item => item.id === name)?.color
      if (!color) {
        return {
          strokeWeight: 1,
          fillOpacity: 1
        }
      }

      return {
        fillColor: color,
        strokeWeight: 1,
        fillOpacity: name === AREA_TYPE.IMOVEL.id ? 0.5 : 1
      }
    })
  }, [
    geometry,
    intersectAreaPreservacaoPermanente,
    intersectReservaLegal,
    intersectTerrasIndigenasEAldeias,
    intersectAreaDesmatamento,
    intersectUnidadesDeConservacao
  ])

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_AUTHORIZATION_KEY
  })

  if (loadError) {
    console.error(loadError)
    return <Box><Typography>Map cannot be loaded right now, sorry.</Typography></Box>
  }

  if (!isLoaded) {
    return <LoadingContainer />
  }

  const legends = Object.values(AREA_TYPE).map(item => ({ id: item.id, label: item.label, color: item.color }))

  return (
    <Box>
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={mapContainerStyle}
        options={{
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          }
        }}
      />
      {showLegend &&
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={1}>
            {legends.map(legend => (
              <Grid item xs={2} key={legend.id}>
                <Box>
                  <Box sx={{ width: '1px', height: '1px', padding: 1, backgroundColor: legend.color, display: 'inline-block', verticalAlign: 'middle' }} />
                  <Typography sx={{ display: 'inline', ml: 1 }}>{legend.label}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      }
    </Box>
  )
})

DynamicGoogleMaps.displayName = 'DynamicGoogleMaps'

export default DynamicGoogleMaps
