import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { CognitoIdentityCredentials, fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import {
  LambdaClient as LambdaClientAWS,
  LambdaClientConfig,
  InvokeCommand
} from '@aws-sdk/client-lambda'
import { Buffer } from 'buffer'

import { AWS_COGNITO_REGION, AWS_IDENTITY_POOL_ID, AWS_IDENTITY_POOL_REGION, AWS_USER_POOLS_ID } from '../config/env'

export interface LambdaClientProps {
  idToken: string
  lambdaRegion: string
  identityPoolId?: string
  identityPoolRegion?: string
  lambdaClient?: LambdaClientAWS
  credentials?: LambdaClientConfig['credentials']
}

export type LambdaClientType = {
  invokeFunction: (input: { functionName: string, body: any }) => Promise<any>
  _client: LambdaClientAWS
}

const LambdaClient = ({
  identityPoolId,
  lambdaRegion,
  identityPoolRegion,
  lambdaClient,
  credentials,

  idToken
}: LambdaClientProps): LambdaClientType => {
  const internalRegion = identityPoolRegion || AWS_IDENTITY_POOL_REGION
  const internalIdentityPoolId = identityPoolId || AWS_IDENTITY_POOL_ID

  const internalCredentials =
    credentials || fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: internalRegion }),
      identityPoolId: internalIdentityPoolId,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: idToken
      }
    })

  const client = lambdaClient || new LambdaClientAWS({
    region: lambdaRegion,
    credentials: internalCredentials
  })

  const invokeFunction = async ({ functionName, body }: { functionName: string, body?: any }) => {
    const command = new InvokeCommand({
      FunctionName: functionName,
      Payload: Buffer.from(
        JSON.stringify(body)
      )
    })

    try {
      const data = await client.send(command)
      if (!data.Payload) {
        return data
      }

      const jsonString = Buffer.from(data.Payload).toString('utf8')
      const parsedPayload = JSON.parse(jsonString)

      if (parsedPayload.errorMessage) {
        throw new Error(parsedPayload.errorMessage)
      }

      const statusCode = parsedPayload.statusCode

      if (statusCode && statusCode >= 400) {
        const body = JSON.parse(parsedPayload.body)
        throw new Error(`${body.message}`)
      }

      return parsedPayload
    } catch (err) {
      if (!(err instanceof Error)) {
        return
      }

      const hasMessage = err?.message
      const message = err?.message ?? 'Error performing query'

      if (hasMessage) {
        if (message.includes('Task timed out after')) {
          throw new Error('Timed out, try another data range')
        }
        throw new Error(`Please forward the error message to support:\n${message}`)
      }
      throw new Error(message)
    }
  }

  return {
    _client: client,
    invokeFunction
  }
}

export class StaticLambdaClient {
  private static instances: Map<string, LambdaClientType> = new Map()
  private static idToken?: string
  private static credentials?: CognitoIdentityCredentials

  private static async resolveCredentials () {
    if (!StaticLambdaClient.idToken) {
      throw new Error('Id token is required')
    }

    const credentials = await fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: AWS_IDENTITY_POOL_REGION }),
      identityPoolId: AWS_IDENTITY_POOL_ID,
      logins: {
        [`cognito-idp.${AWS_COGNITO_REGION}.amazonaws.com/${AWS_USER_POOLS_ID}`]: StaticLambdaClient.idToken
      }
    })()

    StaticLambdaClient.credentials = credentials
  }

  private static resolveInstance ({ lambdaRegion }: { lambdaRegion: string }) {
    if (!StaticLambdaClient.idToken) {
      throw new Error('Id token is required')
    }

    const instance = LambdaClient({ idToken: StaticLambdaClient.idToken, credentials: StaticLambdaClient.credentials, lambdaRegion })
    StaticLambdaClient.instances.set(lambdaRegion, instance)

    return instance
  }

  static async getInstance ({ idToken, lambdaRegion = 'us-east-1' }: { idToken: string, lambdaRegion?: string }): Promise<LambdaClientType> {
    const isNewToken = !StaticLambdaClient.idToken || StaticLambdaClient.idToken !== idToken
    if (isNewToken) {
      StaticLambdaClient.idToken = idToken
      StaticLambdaClient.credentials = undefined
      StaticLambdaClient.instances.delete(lambdaRegion)
      await StaticLambdaClient.resolveCredentials()
    }

    const isCredentialsExpired = StaticLambdaClient.credentials?.expiration && StaticLambdaClient.credentials.expiration.getTime() <= Date.now()
    if (isCredentialsExpired) {
      await StaticLambdaClient.resolveCredentials()
    }

    const isNewInstanceRequired = isNewToken || isCredentialsExpired

    const instance = StaticLambdaClient.instances.get(lambdaRegion)

    if (!instance || isNewInstanceRequired) {
      return StaticLambdaClient.resolveInstance({ lambdaRegion })
    }

    return instance
  }
}

export default LambdaClient
