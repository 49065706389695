import React from 'react'

import DescriptionIcon from '@mui/icons-material/Description'
import GavelIcon from '@mui/icons-material/Gavel'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PolicyIcon from '@mui/icons-material/Policy'
import AgricultureIcon from '@mui/icons-material/Agriculture'
import FactCheckIcon from '@mui/icons-material/FactCheck'

import { STAGE } from './env'

export const PAGES = {
  MATRICULA: {
    id: 'matricula',
    url: '/matricula',
    name: 'Matrícula',
    icon: <DescriptionIcon />
  },
  CONTRATO: {
    id: 'contrato-social',
    url: '/contrato-social',
    name: 'Contrato Social',
    icon: <GavelIcon />
  },
  FATURAMENTO: {
    id: 'faturamento',
    url: '/faturamento',
    name: 'Faturamento',
    icon: <MonetizationOnIcon />
  },
  PROPRIEDADE: {
    id: 'propriedade',
    url: '/propriedade',
    name: 'Propriedade',
    icon: <AgricultureIcon />
  },
  PETICAO_INICIAL: {
    id: 'peticao-inicial',
    url: '/peticao-inicial',
    name: 'Petição Inicial',
    icon: <PolicyIcon />
  },
  CERTIDOES_RURAIS: {
    id: 'cetidoes-rurais',
    url: '/certidoes-rurais',
    name: 'Certidões rurais',
    icon: <FactCheckIcon />
  }
}

export const DATE_FORMAT = {
  DAILY: 'dd/MM/yyyy',
  MONTHLY: 'MM/yyyy'
}

export const CSV_ENCODING = 'ISO-8859-1'
export const CSV_DELIMITER = ';'
export const BUCKET_NAME = 'contratos-warehouse'

export const MATRICULA_RAW_PATH = `stage=${STAGE}/resource=matricula_imovel/type=raw`
export const MATRICULA_V2_RAW_PATH = `stage=${STAGE}/resource=matricula_v2/type=raw`
