import * as geojson from 'geojson'
import axios from 'axios'

import { Area } from '../../lib/report'
import { StaticS3Client } from '../../lib/s3'
import { StaticLambdaClient } from '../../lib/lambda'

import { capitalize, toCamelCase } from '../../utils/formatter'
import { hashObject } from '../../utils/crypto'

import { API_BASE_URL, STAGE } from '../../config/env'
import { BUCKET_NAME } from '../../config/consts'

import { getAccessToken, getIdToken, getUserData } from '../aws'

export interface IFileItem {
  name: string,
  content: string,
  type: string
  _raw: File
}

export type ToGeojsonInput = {
  type: 'file' | 'manual' | 'car' | 'matricula',
  file?: IFileItem,
  matricula?: {
    text: string
    uf: string
  }
  manual?: Array<[number | null, number | null]>
  car?: string
}

export type ToGeojsonOutput = {
  fileId: string
  path: string
}

export const toGeojson = async ({ type, file, manual, car, matricula }: ToGeojsonInput): Promise<ToGeojsonOutput> => {
  const url = `${API_BASE_URL}/to_geojson`

  const idToken = await getIdToken()
  const accessToken = await getAccessToken()
  const { tenant, username } = await getUserData()

  const s3Client = await StaticS3Client.getInstance({ idToken })

  if (type === 'manual') {
    if (!manual) {
      throw new Error('geojson is required')
    }

    if (manual.some(coord => typeof coord[0] !== 'number' || typeof coord[1] !== 'number')) {
      throw new Error('invalid coordinates')
    }

    const content = JSON.stringify({
      type: 'FeatureCollection',
      features: [
        {
          id: '0',
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [manual]
          }
        }
      ]
    })

    const fileId = hashObject(content)
    const key = `stage=${STAGE.toLowerCase()}/resource=propriedade_rural/tenant=${tenant}/user=${username}/file_id=${fileId}/format=geojson`

    await s3Client.writeFile({
      bucket: BUCKET_NAME,
      key,
      data: content
    })

    return {
      fileId,
      path: key
    }
  }

  if (type === 'car') {
    if (!car || car.length === 0) {
      throw new Error('car is required')
    }

    const content = JSON.stringify({
      car
    })

    const fileId = hashObject(content)
    const key = `stage=${STAGE.toLowerCase()}/resource=propriedade_rural/tenant=${tenant}/user=${username}/file_id=${fileId}/format=car`

    await s3Client.writeFile({
      bucket: BUCKET_NAME,
      key,
      data: content
    })

    const { data } = await axios.post(url, {
      input_type: type,
      input_path: key,
      file_id: fileId
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (data.error) {
      throw new Error(data.message)
    }

    return {
      fileId,
      path: data.geojson_path
    }
  }

  if (type === 'matricula') {
    if (!matricula || matricula.text.length === 0) {
      throw new Error('matricula is required')
    }

    if (matricula.uf.length === 0) {
      throw new Error('uf is required')
    }

    const content = JSON.stringify({
      uf: matricula.uf,
      raw_text: matricula.text
    })

    const fileId = hashObject(content)
    const key = `stage=${STAGE.toLowerCase()}/resource=propriedade_rural/tenant=${tenant}/user=${username}/file_id=${fileId}/format=raw_text`

    await s3Client.writeFile({
      bucket: BUCKET_NAME,
      key,
      data: content
    })

    const { data } = await axios.post(url, {
      input_type: 'raw_text',
      input_path: key,
      file_id: fileId
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })

    if (data.error) {
      throw new Error(data.message)
    }

    return {
      fileId,
      path: data.geojson_path
    }
  }

  if (!file) {
    throw new Error('file is required')
  }

  const format = file.type.includes('zip') ? 'shp' : 'kml'

  const fileId = hashObject(file.content)
  const key = `stage=${STAGE.toLowerCase()}/resource=propriedade_rural/tenant=${tenant}/user=${username}/file_id=${fileId}/format=${format}`

  await s3Client.writeFile({
    bucket: BUCKET_NAME,
    key,
    data: file._raw,
    contentType: file.type
  })

  const { data } = await axios.post(url, {
    input_type: format,
    input_path: key,
    file_id: fileId
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  if (data.error) {
    throw new Error(data.message)
  }

  return {
    fileId,
    path: data.geojson_path
  }
}

const AREA_TYPE = {
  IMOVEL: {
    id: 'imovel',
    label: 'Área do Imóvel',
    color: '#000000'
  },
  RESERVA: {
    id: 'reserva',
    label: 'Reserva Legal',
    color: '#027639'
  },
  PRESERVACAO: {
    id: 'preservacao',
    label: 'Área de Preservação Permanente',
    color: '#cc0000'
  },
  TERRAS_INDIGENAS_E_ALDEIAS: {
    id: 'terrasIndigenasEAldeias',
    label: 'Terras Indígenas e Aldeias',
    color: '#df9dc0'
  },
  UNIDADES_DE_CONSERVACAO: {
    id: 'unidadesDeConservacao',
    label: 'Unidades de Conservação',
    color: '#ff4940'
  },
  AREA_DESMATAMENTO: {
    id: 'areaDesmatamento',
    label: 'Área de desmatamento',
    color: '#ff8246'
  }
} as const

type AREA_TYPE_KEY = keyof typeof AREA_TYPE

export type GenerateMapLayerInput = {
  fileId: string
  path: string
}

export type GenerateMapLayerOutput = {
  property: Area
  others: Area[]
}

export const generateMapLayer = async ({ fileId, path }: GenerateMapLayerInput): Promise<GenerateMapLayerOutput> => {
  interface Response {
    chosenCoordinates: ResponseChosenCoordinates
    intersectApp: ResponseIntersect
    intersectRl: ResponseIntersect
    intersectTerrasIndigenasDominiais?: ResponseIntersect
    intersectTerrasIndigenasNaoHomologadas?: ResponseIntersect
    intersectTerrasIndigenasHomologadas?: ResponseIntersect
    intersectTerrasIndigenasReservas?: ResponseIntersect
    intersectTerrasIndigenasSobInterdicao?: ResponseIntersect
    intersectTerrasIndigenasEmEstudo?: ResponseIntersect
    intersectAldeiasIndigenas?: ResponseIntersect
    intersectUnidadesDeConservacao?: ResponseIntersect
    intersectAmazoniaLegal?: ResponseIntersect
    intersectAmazonia: ResponseIntersect
    intersectCaatinga: ResponseIntersect
    intersectCerrado: ResponseIntersect
    intersectMataAtlantica: ResponseIntersect
    intersectPampa: ResponseIntersect
    intersectPantanal: ResponseIntersect
  }

  const parseIntersectData = (data: ResponseIntersect['data'] | undefined | null, intersectType: IntersectType, areaType: AREA_TYPE_KEY): Area[] => {
    const typeDict: { [key: string]: string } = {
      intersectTerrasIndigenasDominiais: 'Dominial',
      intersectTerrasIndigenasNaoHomologadas: 'Não Homologada',
      intersectTerrasIndigenasHomologadas: 'Homologada',
      intersectTerrasIndigenasReservas: 'Reserva',
      intersectTerrasIndigenasSobInterdicao: 'Sob Interdição',
      intersectTerrasIndigenasEmEstudo: 'Em Estudo',
      intersectAldeiasIndigenas: 'Aldeia',
      intersectAmazoniaLegal: 'Amazônia Legal',
      intersectAmazonia: 'Amazônia',
      intersectCaatinga: 'Caatinga',
      intersectCerrado: 'Cerrado',
      intersectMataAtlantica: 'Mata Atlântica',
      intersectPampa: 'Pampa',
      intersectPantanal: 'Pantanal'
    }

    const type = typeDict[intersectType as string]

    return (data || []).map((item): Area => {
      return {
        type: 'others',
        options: {
          style: {
            color: AREA_TYPE[areaType].color
          }
        },
        geoJson: {
          type: 'Feature',
          properties: {
            name: AREA_TYPE[areaType].label,
            desc: type ?? item.desc
          },
          geometry: item.geoJson as geojson.Geometry
        }
      }
    })
  }

  const idToken = await getIdToken()

  const lambdaClient = await StaticLambdaClient.getInstance({ idToken })

  const accessToken = await getAccessToken()

  const payload = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({
      input_path: path,
      file_id: fileId
    })
  }

  const { statusCode, body: response } = await lambdaClient.invokeFunction({
    functionName: `GenerateMapLayers-Matricula-Function-${capitalize(STAGE)}`,
    body: payload
  }).then(response => {
    if (response.body) {
      return {
        ...response,
        body: JSON.parse(response.body)
      }
    }
  })

  if (statusCode !== 200) {
    throw new Error(response.message)
  }

  if (response.error) {
    throw new Error(response.message)
  }

  const s3Client = await StaticS3Client.getInstance({ idToken })

  const content = await s3Client.readFile({
    bucket: BUCKET_NAME,
    key: response.geojson_path
  })

  const parsed = toCamelCase(content) as Response

  const areaDesmatamentoData: Area[] = []
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectAmazoniaLegal?.data, 'intersectAmazoniaLegal', 'AREA_DESMATAMENTO'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectAmazonia?.data, 'intersectAmazonia', 'AREA_DESMATAMENTO'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectCaatinga?.data, 'intersectCaatinga', 'AREA_DESMATAMENTO'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectCerrado?.data, 'intersectCerrado', 'AREA_DESMATAMENTO'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectMataAtlantica?.data, 'intersectMataAtlantica', 'AREA_DESMATAMENTO'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectPampa?.data, 'intersectPampa', 'AREA_DESMATAMENTO'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectPantanal?.data, 'intersectPantanal', 'AREA_DESMATAMENTO'))

  const terrasIndigenasEAldeiasData: Area[] = []
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasDominiais?.data, 'intersectTerrasIndigenasDominiais', 'TERRAS_INDIGENAS_E_ALDEIAS'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasNaoHomologadas?.data, 'intersectTerrasIndigenasNaoHomologadas', 'TERRAS_INDIGENAS_E_ALDEIAS'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasHomologadas?.data, 'intersectTerrasIndigenasHomologadas', 'TERRAS_INDIGENAS_E_ALDEIAS'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasReservas?.data, 'intersectTerrasIndigenasReservas', 'TERRAS_INDIGENAS_E_ALDEIAS'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasSobInterdicao?.data, 'intersectTerrasIndigenasSobInterdicao', 'TERRAS_INDIGENAS_E_ALDEIAS'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasEmEstudo?.data, 'intersectTerrasIndigenasEmEstudo', 'TERRAS_INDIGENAS_E_ALDEIAS'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectAldeiasIndigenas?.data, 'intersectAldeiasIndigenas', 'TERRAS_INDIGENAS_E_ALDEIAS'))

  const areaPreservacaoPermanente = parseIntersectData(parsed.intersectApp.data, 'intersectApp', 'PRESERVACAO')
  const areaReservaLegal = parseIntersectData(parsed.intersectRl.data, 'intersectRl', 'RESERVA')
  const areaUnidadesDeConservacao = parseIntersectData(parsed.intersectUnidadesDeConservacao?.data, 'intersectUnidadesDeConservacao', 'UNIDADES_DE_CONSERVACAO')

  const areas: Area[] = [
    areaDesmatamentoData,
    terrasIndigenasEAldeiasData,
    areaPreservacaoPermanente,
    areaReservaLegal,
    areaUnidadesDeConservacao
  ].flat()

  const property: Area = {
    geoJson: {
      type: 'Feature',
      properties: {
        name: 'Propriedade'
      },
      geometry: parsed.chosenCoordinates.data.geoJson
    },
    options: {
      style: {
        fillColor: 'transparent',
        color: '#000'
      }
    },
    type: 'property'
  } as Area

  return {
    property,
    others: areas
  }
}

type IntersectType = 'intersectTerrasIndigenasDominiais' | 'intersectTerrasIndigenasNaoHomologadas' | 'intersectTerrasIndigenasHomologadas' | 'intersectTerrasIndigenasReservas' | 'intersectTerrasIndigenasSobInterdicao' | 'intersectTerrasIndigenasEmEstudo' | 'intersectAldeiasIndigenas' | 'intersectUnidadesDeConservacao' | 'intersectAmazoniaLegal' | 'intersectAmazonia' | 'intersectCaatinga' | 'intersectCerrado' | 'intersectMataAtlantica' | 'intersectPampa' | 'intersectPantanal' | 'intersectAmazoniaLegal' | 'intersectAmazonia' | 'intersectCaatinga' | 'intersectCerrado' | 'intersectMataAtlantica' | 'intersectPampa' | 'intersectPantanal' | 'intersectApp' | 'intersectRl'

interface ResponseChosenCoordinates {
  data: {
    geoJson: ResponseGeoJson
  }
}
interface ResponseIntersect {
  status: ResponseStatus
  reason: string
  data: Array<{
    desc: string
    id: string
    geoJson: Omit<ResponseGeoJson, 'geometries'>
  }>
}

type ResponseStatus = 'OK' | 'FAILED'

interface ResponseGeoJson {
  coordinates: number[][][]
  type: string
  geometries: any
}
