import { combineReducers, createStore } from 'redux'
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension'

const rootReducer = combineReducers({
})

type ReducerType = ReturnType<typeof rootReducer>

const persistConfig: PersistConfig<ReducerType> = {
  key: 'root',
  storage,
  version: 1,
  whitelist: ['filters']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancer = composeWithDevTools
export const store = createStore(
  persistedReducer,
  composeEnhancer()
)
export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
