import React, { useEffect, useState } from 'react'
import { useResizeDetector } from 'react-resize-detector'
import plotComponentFactory from 'react-plotly.js/factory'
import * as Plotly from 'plotly.js-dist'
import { PlotParams } from 'react-plotly.js'
import { Box, CircularProgress, Typography } from '@mui/material'

const Plot = plotComponentFactory(Plotly)

export interface ResponsivePlotProps extends PlotParams {
  loading?: boolean
  loadingText?: string
  unloadedText?: string
}

export default function ResponsivePlot ({ loading, loadingText = 'Loading', unloadedText, ...props }: ResponsivePlotProps): JSX.Element {
  const [loaded, setLoaded] = useState(false)
  const { width, height, ref } = useResizeDetector({})

  const text = unloadedText || 'Pesquise para ver o gráfico'

  useEffect(() => {
    if (loading || props.data?.length > 0) {
      setLoaded(true)
    }
  }, [props.data, loading])

  return (
    <div ref={ref} style={{ display: 'flex', height: '100%' }}>
      {loading
        ? (<Box
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        width={width || '100%'}
        height={height || 440}
      >
        <CircularProgress
          size={54}
        />
        <Box mt={4}>{loadingText}</Box>
      </Box>)
        : !loaded
            ? (<Box
        width={width || '100%'}
        height={height || 440}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Typography variant='h5' component='div'>{text}</Typography>
      </Box>)
            : (
        <Plot
          {...props}
          config={{
            displaylogo: false,
            ...props.config,
            modeBarButtonsToRemove: [
              'pan2d',
              'autoScale2d',
              ...(props.config?.modeBarButtonsToRemove || [])
            ]
          }}
          layout={{
            ...props?.layout,
            ...{
              width: props?.layout?.width || width,
              height: props?.layout?.height || height
            }
          }}
        />)}
    </div>
  )
}
