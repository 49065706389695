import axios from 'axios'

import { getAccessToken } from '../aws'

import { API_BASE_URL } from '../../config/env'

import { toCamelCase } from '../../utils/formatter'

const TYPE_DICT = {
  contrato: 'contrato_social',
  faturamento: 'faturamento',
  peticaoInicial: 'peticao_inicial',
  matriculaV2: 'matricula_v2',
  matricula: 'matricula'
}

type DocumentType = keyof typeof TYPE_DICT

export interface GetDocumentProps {
  type: DocumentType
  fname: string
}
export const getDocument = async ({ fname, type }: GetDocumentProps): Promise<unknown> => {
  const accessToken = await getAccessToken()

  const resolvedType = TYPE_DICT[type]

  const { data } = await axios.post(`${API_BASE_URL}/get_document`, {
    fname,
    document_type: resolvedType
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  const formattedData = toCamelCase(data)

  return formattedData
}

export interface ListDocumentsProps {
  type: DocumentType
}

export interface IListDocumentItem {
  originalFileName:string
  fname: string
  elapsedTime?: number
  status: 'processed' | 'pending' | 'failed'
  propertyType?: 'URBANO' | 'RURAL'
  isAlienado?: boolean
  googleMapsStatus?: 'OK' | 'FAILED'
}

export const listDocuments = async ({ type }: ListDocumentsProps):Promise<Array<IListDocumentItem>> => {
  const accessToken = await getAccessToken()

  const resolvedType = TYPE_DICT[type]

  const { data } = await axios.post(`${API_BASE_URL}/list_documents`, {
    document_type: resolvedType
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  type Response = Array<{
    id: string
    original: string
    fname: string
    isAlienado?: boolean
    userId: string
    docType: string
    elapsedTime?: number
    tipoImovel?: 'URBANO' | 'RURAL'
    googleMapsStatus?: 'OK' | 'FAILED'
    status: 'processed' | 'pending' | 'failed'
  }>

  const formattedData = toCamelCase(data) as Response

  return formattedData.map(item => ({
    originalFileName: item.original ?? item.fname,
    fname: item.fname,
    status: item.status,
    elapsedTime: item.elapsedTime,
    isAlienado: item.isAlienado,
    propertyType: item.tipoImovel,
    googleMapsStatus: item.googleMapsStatus
  })).sort((a, b) => {
    if (a.status === b.status) {
      return a.fname.localeCompare(b.fname)
    }

    if (a.status === 'pending') {
      return -1
    }

    if (b.status === 'pending') {
      return 1
    }

    return 0
  })
}
