import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import ErrorStackParser from 'error-stack-parser'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function UnexpectedErroPage ({ error }: { error: Error }) {
  const myError = ErrorStackParser.parse(error)

  return (
    <Box sx={{ display: 'flex', height: '100%', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <Typography align='center' component='h2' variant='h5' >
          Ops! Aconteceu um erro inesperado
        </Typography>
        <Typography align='center' component='p' sx={{ mt: 1 }} >
          Recarregue a página e tente novamente
        </Typography>
        <Typography align='center' component='p' sx={{ mt: 0 }} >
          Se o erro persistir, entre em contato com o suporte da Indrema
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='details'
              id='details'
            >
              <Typography>Ver detalhes do erro</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Mensagem: {error.message}
              </Typography>
              <Typography>
                {myError.map((e, i) => (
                  <Box key={i}>
                    <Typography>
                      {'  '}  at {e.functionName} - {e.getFileName()} - {e.columnNumber}:{e.lineNumber}
                    </Typography>
                  </Box>
                ))}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Box >
  )
}
