import React, { useState } from 'react'
import { Box, Container, IconButton, Toolbar, Typography } from '@mui/material'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'

import lazyLoad from './lib/lazyLoad'

import { PAGES } from './config/consts'
import { HEAD_TITLE, LOGO, SCOPE } from './config/env'

import { AppBar, DrawerMenu, ErrorBoundary, ErrorLoadPage, LazyLoadPage } from './components'

import {
  MatriculaProps,
  ContratoProps,
  FaturamentoProps,
  PeticaoInicialProps,
  PropriedadePageProps,
  CertidoesRuraisProps
} from './pages'

const MatriculaPage = lazyLoad<MatriculaProps>(() => import('./pages/matricula'), ErrorLoadPage, 3, 1000)
const ContratoPage = lazyLoad<ContratoProps>(() => import('./pages/contrato'), ErrorLoadPage, 3, 1000)
const FaturamentoPage = lazyLoad<FaturamentoProps>(() => import('./pages/faturamento'), ErrorLoadPage, 3, 1000)
const PeticaoInicialPage = lazyLoad<PeticaoInicialProps>(() => import('./pages/peticao-inicial'), ErrorLoadPage, 3, 1000)
const PropriedadePage = lazyLoad<PropriedadePageProps>(() => import('./pages/propriedade'), ErrorLoadPage, 3, 1000)
const CertidoesRuraisPage = lazyLoad<CertidoesRuraisProps>(() => import('./pages/certidoes-rurais'), ErrorLoadPage, 3, 1000)

const drawerWidth = 240

function App (): JSX.Element {
  const location = useLocation()

  const [openSide, setOpenSide] = useState(false)

  const handleToggleDrawer = (): void => {
    setOpenSide(!openSide)
  }

  const resolvePage = (): string => {
    const dict: { [key: string]: string } = Object.values(PAGES).reduce((obj: { [url: string]: string }, { url, name }) => {
      obj[url] = name
      return obj
    }, {})

    const path = location.pathname
    return dict[path] || 'Dashboard'
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='absolute' open={openSide} drawerWidth={drawerWidth}>
        <Toolbar
          sx={{
            pr: '24px' // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleToggleDrawer}
            sx={{
              marginRight: '36px',
              ...(openSide && { display: 'none' })
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {resolvePage()}
          </Typography>
          <Box>
            <img src={LOGO} alt={HEAD_TITLE} height={24} />
          </Box>
        </Toolbar>
      </AppBar>
      <DrawerMenu open={openSide} onToggle={handleToggleDrawer} drawerWidth={drawerWidth} />
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          flexDirection: 'column',
          display: 'flex',
          overflow: 'hidden'
        }}
      >
        <Toolbar />
        <Box sx={{ pt: 2, pb: 2, flex: 1, overflow: 'auto' }} id='container'>
          <Container maxWidth={false} sx={{ flex: 1 }}>
            <LazyLoadPage>
              <Routes>
                <Route path={PAGES.MATRICULA.url}>
                  <Route index element={<ErrorBoundary><MatriculaPage /></ErrorBoundary>} />
                  <Route path=':fname' element={<ErrorBoundary><MatriculaPage /></ErrorBoundary>} />
                </Route>
                <Route path={PAGES.CONTRATO.url}>
                  <Route index element={<ErrorBoundary><ContratoPage /></ErrorBoundary>} />
                  <Route path=':fname' element={<ErrorBoundary><ContratoPage /></ErrorBoundary>} />
                </Route>
                <Route path={PAGES.FATURAMENTO.url}>
                  <Route index element={<ErrorBoundary><FaturamentoPage /></ErrorBoundary>} />
                  <Route path=':fname' element={<ErrorBoundary><FaturamentoPage /></ErrorBoundary>} />
                </Route>
                {SCOPE !== 'tentoscap' && <Route path={PAGES.PETICAO_INICIAL.url}>
                  <Route index element={<ErrorBoundary><PeticaoInicialPage /></ErrorBoundary>} />
                  <Route path=':fname' element={<ErrorBoundary><PeticaoInicialPage /></ErrorBoundary>} />
                </Route>}
                <Route path={PAGES.PROPRIEDADE.url} element={<ErrorBoundary><PropriedadePage /></ErrorBoundary>} />
                <Route path={PAGES.CERTIDOES_RURAIS.url} element={<ErrorBoundary><CertidoesRuraisPage /></ErrorBoundary>} />
                <Route path='*' element={<Navigate to={PAGES.MATRICULA.url} state={{ from: location }} replace />} />
              </Routes>
            </LazyLoadPage>
          </Container>
        </Box>
      </Box>

    </Box>
  )
}

export default App
