import React, { useState } from 'react'
import { Link, Typography, TypographyProps } from '@mui/material'

export interface TypographyWithDotsProps extends TypographyProps{
  maxCharacters?: number
}

export default function TypographyWithDots ({ maxCharacters, children, ...props }: TypographyWithDotsProps) {
  const [showAll, setShowAll] = useState(false)

  if (typeof children !== 'string') {
    return (<Typography {...props}>
    {children}
  </Typography>)
  }

  return (
      <Typography {...props}>
        {maxCharacters && !showAll && children.length > maxCharacters ? `${children.substring(0, maxCharacters)}...` : children}
        {maxCharacters && !showAll && children.length > maxCharacters && (<>{' '}<Link style={{ cursor: 'pointer' }} onClick={() => setShowAll(true)}>ver mais</Link></>)}
        {maxCharacters && showAll && (<>{' '}<Link style={{ cursor: 'pointer' }} onClick={() => setShowAll(false)}>ver menos</Link></>) }
      </Typography>
  )
}
