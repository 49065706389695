import { getIdToken, getAccessToken } from '../aws'
import axios from 'axios'

import { StaticS3Client } from '../../lib/s3'

import { BUCKET_NAME } from '../../config/consts'
import { API_BASE_URL, STAGE } from '../../config/env'

import { hashObject } from '../../utils/crypto'
import { capitalize } from '../../utils/formatter'

export const FUNCTIONS_NAME = {
  PROCESS_CONTRACT: `SmartAssistant-ContratoSocial-Function-${capitalize(STAGE)}`
} as const

export interface IFileItem {
  name: string,
  content: string,
  type: string
  _raw: File
}

export interface GetHistoryProps {
  file: IFileItem
}

export interface IGetHistory {
  descricaoContrato?: IGetHistoryDescricaoContrato
  sociosRetirantes?: IGetHistorySocio[]
  socios?: IGetHistorySocio[]
}

export interface IGetHistoryDescricaoContrato {
  nomeEmpresa?: string
  descricao?: string
  capitalSocial?: string
  cnpj?: string
}

export interface IGetHistorySocio {
  nome?: string
  tipoDeSocio?: string
  documento?: string
  participacao?: string
}

export const getHistory = async ({ file }: GetHistoryProps): Promise<void> => {
  const idToken = await getIdToken()
  const accessToken = await getAccessToken()

  const s3Client = await StaticS3Client.getInstance({ idToken })
  const id = hashObject(file.content)
  const fname = `${id}`
  await s3Client.writeFile({
    bucket: BUCKET_NAME,
    key: `stage=${STAGE}/resource=contrato_social/type=raw/${fname}`,
    data: file._raw,
    contentType: file.type
  })

  await axios.post(`${API_BASE_URL}/start`, {
    fname: id,
    original_fname: file.name,
    document_type: 'contrato_social'
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

interface Response {
  descricaoContrato: ResponseDescricaoContrato
  sociosRetirantes: any[]
  socios: ResponseSocio[]
}

interface ResponseDescricaoContrato {
  nomeEmpresa: string
  'descrição': string
  capitalSocial: string
  cnpj: string
}

interface ResponseSocio {
  nome: string
  tipoDeSocio: string
  documento: string
  participacao: string
}

export const parseContrato = (contrato: Response): IGetHistory => {
  return {
    ...contrato,
    descricaoContrato: {
      ...contrato.descricaoContrato,
      descricao: contrato.descricaoContrato['descrição']
    }
  }
}
