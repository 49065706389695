import { getIdToken, getAccessToken } from '../aws'
import axios from 'axios'

import { StaticS3Client } from '../../lib/s3'

import { BUCKET_NAME, MATRICULA_RAW_PATH, MATRICULA_V2_RAW_PATH } from '../../config/consts'
import { API_BASE_URL, STAGE } from '../../config/env'

import { hashObject } from '../../utils/crypto'
import { factoryDate } from '../../utils/date'
import { notEmpty, notEmptyString } from '../../utils/array'
import { capitalize, toCamelCase } from '../../utils/formatter'

import { IListDocumentItem } from './documento'
import { isAlienadoTransactionType } from '../../lib/matricula'

export const FUNCTIONS_NAME = {
  PROCESS_CONTRACT: `SmartAssistant-MatriculaImovel-Function-${capitalize(STAGE)}`
} as const

export interface IGetHistory {
  dadosImovel: IGetHistoryDadosImovel
  historico: Array<IGetHistoryItem>
  certidao?: string
  blocks: Array<IGetHistoryBlocks>
  alertas: { [key: string]: boolean }
  metadata: {
    googleMaps?: IGetHistoryGoogleMapsMetadata
    geolocation: IGetHistoryGeolocation
    chosenCoordinates?: IGetHistoryChosenCoordinates
    meteorologicalAnalysis: IGetHistoryMeteorologicalAnalysis
    satelliteImages: Array<IGetHistorySatelliteImageItem>
    intersectAreaPreservacaoPermanente?: IGetHistoryIntersect
    intersectReservaLegal?: IGetHistoryIntersect
    intersectTerrasIndigenasEAldeias?: IGetHistoryIntersect
    intersectAreaDesmatamento?: IGetHistoryIntersect
    intersectUnidadesDeConservacao?: IGetHistoryIntersect
  }
}

export interface IGetHistoryAlertas {
  areaContaminada: boolean
  areaDeRecreio: boolean
  concessaoDePatrimonioDeAfetacaoOuRegimeDeAfetacao: boolean
  encargos: boolean
  imovelArrematadoPorLeilao: boolean
  imovelPenhorado: boolean
  impenhorabilidade: boolean
  inalienabilidade: boolean
  incomunicabilidade: boolean
  indisponibilidade: boolean
  leiloesNegativos: boolean
  levantamentoDeSequestro: boolean
  regimeDeAforamento: boolean
  registroTorrens: boolean
  remissaoDeForo: boolean
  renunciaDeUsufruto: boolean
  servidaoDePassagem: boolean
  tombamentoDeBemImovel: boolean
  usufruto: boolean
}

export interface IGetHistoryDadosImovel {
  isAlienado?: boolean
  codigoImo?: string
  denominacao?: string
  tipoImovel?: string,
  cartorio?: string,
  comarca?: string,
  codigoCns?: string,
  numeroMatricula?: string,
  isRegisteredSncr?: boolean
  matriculaAnterior?: string[],
  dataAbertura?: string,
  descricao?: string,
  descriptionRaw?: string
  confrontacoesDoTerreno?: string,
  descricaoDaEdificacao?: string,
  confrontacoesDaEdificacao?: string,
  areaUtil?: string,
  areaComum?: string,
  areaGaragem?: string,
  areaTerreno?: string,
  vagasGaragem?: string,
  endereco?: {
    logradouro?: string,
    numero?: string,
    bairro?: string,
    complemento?: string,
    cidade?: string,
    uf?: string,
    cep?: string,
    zona?: string,
    quadra?: string,
    lote?: string,
  },
  proprietarios?: IGetHistoryProprietario[]
}

export interface IGetHistoryProprietario {
  nome: string
  documento: string
}

export interface IGetHistoryBlocks {
  page: number,
  blocks: Array<{
    id: string,
    polygon: Array<number>
    text: string,
    label: string,
  }>
}

export interface IGetHistoryItem {
  linkRef: string,
  id: number,
  refIds: string[],
  tipoTransacao?: string,
  date?: string,
  valor?: string,
  unidadeMonetaria?: string,
  tipoGrupo?: string,
  origem?: string,
  cancelamento?: string,
  dataVencimento?: string,
  grauHipoteca?: string,
  vendedor: Array<{
    nome?: string,
    doc?: string,
  }>,
  adquirente: Array<{
    nome?: string,
    doc?: string,
  }>
  desconhecidos: Array<{
    nome?: string,
    doc?: string,
  }>
}

export interface IGetHistoryGoogleMapsMetadata {
  address?: string,
  geolocation?: null | {
    lat: number,
    lng: number
  }
  images?: Array<{
    status: string,
    link: string,
  }>,
}

type Status = 'OK' | 'FAILED'

export interface IGetHistoryGeolocation {
  status: Status
  reason?: string
  data: {
    lat: number
    lng: number
  }
}

export type IGetHistorySigef = {
  status: ResponseStatus
  reason: string
  data: {
    geoJson: GeoJson
  }
}

export type IGetHistoryChosenCoordinates = {
  status: ResponseStatus
  reason: string
  data: {
    geoJson?: GeoJson
  }
}

export interface GeoJson {
  coordinates: number[][][]
  type: string
  geometries: any
}

export interface IGetHistoryMeteorologicalAnalysis {
  status: ResponseStatus
  reason: string[]
  data: Array<IGetHistoryMeteorologicalAnalysisItem>
}

export interface IGetHistoryIntersect {
  status: ResponseStatus
  reason: string
  data: Array<{
    id: string
    desc: string
    geoJson: Omit<ResponseGeoJson, 'geometries'>
  }>
}

export interface IGetHistorySatelliteImageItem {
  status: ResponseStatus
  err?: string
  date: Date
  key?: string
}

export interface IGetHistoryMeteorologicalAnalysisItem {
  date: Date
  lstMonth1Km?: number
  lstMonth1KmFitted?: number
  spei01Month?: number
  spei01MonthFitted?: number
  totalPrecipitationSum?: number
  totalPrecipitationSumFitted?: number
}

export interface IFileItem {
  name: string,
  content: string,
  type: string
  _raw: File
}

export interface GetHistoryProps {
  file: IFileItem
}

export interface InternalGetHistoryProps extends GetHistoryProps {
  version: 'v1' | 'v2'
}

export const getHistory = async ({ file, version }: InternalGetHistoryProps): Promise<Pick<IListDocumentItem, 'originalFileName' | 'fname'>> => {
  const idToken = await getIdToken()
  const accessToken = await getAccessToken()

  const s3Client = await StaticS3Client.getInstance({ idToken })
  const id = hashObject(file.content)
  const fname = `${id}`
  const key = version === 'v1' ? `${MATRICULA_RAW_PATH}/${fname}` : `${MATRICULA_V2_RAW_PATH}/${fname}`
  await s3Client.writeFile({
    bucket: BUCKET_NAME,
    key,
    data: file._raw,
    contentType: file.type
  })

  await axios.post(`${API_BASE_URL}/start`, {
    fname: id,
    original_fname: file.name,
    document_type: version === 'v1' ? 'matricula' : 'matricula_v2'
  }, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  return {
    fname: id,
    originalFileName: file.name
  }
}

export const parseMatricula = (matricula: Response): IGetHistory => {
  const parsed = toCamelCase(matricula) as Response

  const alertas = new Set<string>()

  const mappedTransactions = parsed.historicoTransacoes.map((item) => {
    const alienadoTransactionType = isAlienadoTransactionType(item.tipoTransacao)
    if (alienadoTransactionType) {
      alertas.add(alienadoTransactionType)
    }

    return {
      ...item,
      vendedor: item.vendedorExecutado,
      adquirente: item.adquirenteExequente,
      desconhecidos: item.unknownPeople,
      linkRef: hashObject(item.id.toString()),
      origem: typeof item.origem === 'number' ? hashObject(item.origem.toString()) : undefined,
      cancelamento: typeof item.cancelamento === 'number' ? hashObject(item.cancelamento.toString()) : undefined,
      date: item.data
    }
  })

  const meteorologicalDates = Array.from(new Set([
    ...(parsed.meteorologicalAnalysis?.ecmwf.data || []).map(({ datetime }) => datetime),
    ...(parsed.meteorologicalAnalysis?.lst.data || []).map(({ datetime }) => datetime),
    ...(parsed.meteorologicalAnalysis?.spei.data || []).map(({ datetime }) => datetime)
  ])).sort((a, b) => a - b)

  const meteorologicalData: Array<IGetHistoryMeteorologicalAnalysisItem> = []

  meteorologicalDates.forEach((date) => {
    const ecmwf = parsed.meteorologicalAnalysis?.ecmwf.data.find((item) => item.datetime === date)
    const lst = parsed.meteorologicalAnalysis?.lst.data.find((item) => item.datetime === date)
    const spei = parsed.meteorologicalAnalysis?.spei.data.find((item) => item.datetime === date)

    meteorologicalData.push({
      date: factoryDate(date),
      lstMonth1Km: lst?.lstMonth1Km,
      lstMonth1KmFitted: lst?.lstMonth1KmFitted,
      spei01Month: spei?.spei01Month,
      spei01MonthFitted: spei?.spei01MonthFitted,
      totalPrecipitationSum: ecmwf?.totalPrecipitationSum,
      totalPrecipitationSumFitted: ecmwf?.totalPrecipitationSumFitted
    })
  })

  const meteorologicalStatus: ResponseStatus = [
    parsed.meteorologicalAnalysis?.ecmwf.status,
    parsed.meteorologicalAnalysis?.lst.status,
    parsed.meteorologicalAnalysis?.spei.status
  ].filter(notEmpty).every((status) => status === 'FAILED')
    ? 'FAILED'
    : 'OK'

  const meteorologicalReason = [
    parsed.meteorologicalAnalysis?.ecmwf.reason,
    parsed.meteorologicalAnalysis?.lst.reason,
    parsed.meteorologicalAnalysis?.spei.reason
  ].filter(notEmptyString)

  type IntersectType = 'intersectTerrasIndigenasDominiais' | 'intersectTerrasIndigenasNaoHomologadas' | 'intersectTerrasIndigenasHomologadas' | 'intersectTerrasIndigenasReservas' | 'intersectTerrasIndigenasSobInterdicao' | 'intersectTerrasIndigenasEmEstudo' | 'intersectAldeiasIndigenas' | 'intersectUnidadesDeConservacao' | 'intersectAmazoniaLegal' | 'intersectAmazonia' | 'intersectCaatinga' | 'intersectCerrado' | 'intersectMataAtlantica' | 'intersectPampa' | 'intersectPantanal' | 'intersectAmazoniaLegal' | 'intersectAmazonia' | 'intersectCaatinga' | 'intersectCerrado' | 'intersectMataAtlantica' | 'intersectPampa' | 'intersectPantanal'

  const parseIntersectData = (data: ResponseIntersect['data'] | undefined | null, intersectType: IntersectType): ResponseIntersect['data'] => {
    const typeDict: { [key: string]: string } = {
      intersectTerrasIndigenasDominiais: 'Dominial',
      intersectTerrasIndigenasNaoHomologadas: 'Não Homologada',
      intersectTerrasIndigenasHomologadas: 'Homologada',
      intersectTerrasIndigenasReservas: 'Reserva',
      intersectTerrasIndigenasSobInterdicao: 'Sob Interdição',
      intersectTerrasIndigenasEmEstudo: 'Em Estudo',
      intersectAldeiasIndigenas: 'Aldeia',
      intersectAmazoniaLegal: 'Amazônia Legal',
      intersectAmazonia: 'Amazônia',
      intersectCaatinga: 'Caatinga',
      intersectCerrado: 'Cerrado',
      intersectMataAtlantica: 'Mata Atlântica',
      intersectPampa: 'Pampa',
      intersectPantanal: 'Pantanal'
    }

    const type = typeDict[intersectType as string]
    return (data || []).map((item) => {
      return { ...item, desc: type ? `${type} - ${item.desc}` : item.desc }
    })
  }

  const areaDesmatamentoData: IGetHistoryIntersect['data'] = []
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectAmazoniaLegal?.data, 'intersectAmazoniaLegal'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectAmazonia?.data, 'intersectAmazonia'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectCaatinga?.data, 'intersectCaatinga'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectCerrado?.data, 'intersectCerrado'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectMataAtlantica?.data, 'intersectMataAtlantica'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectPampa?.data, 'intersectPampa'))
  areaDesmatamentoData.push(...parseIntersectData(parsed.intersectPantanal?.data, 'intersectPantanal'))

  const areaDesmatamentoStatus: ResponseStatus = [
    parsed.intersectAmazoniaLegal?.status,
    parsed.intersectAmazonia?.status,
    parsed.intersectCaatinga?.status,
    parsed.intersectCerrado?.status,
    parsed.intersectMataAtlantica?.status,
    parsed.intersectPampa?.status,
    parsed.intersectPantanal?.status
  ].filter(notEmpty).every((status) => status === 'FAILED')
    ? 'FAILED'
    : 'OK'

  const areaDesmatamentoReason = [
    parsed.intersectAmazoniaLegal?.reason,
    parsed.intersectAmazonia?.reason,
    parsed.intersectCaatinga?.reason,
    parsed.intersectCerrado?.reason,
    parsed.intersectMataAtlantica?.reason,
    parsed.intersectPampa?.reason,
    parsed.intersectPantanal?.reason
  ].filter(notEmptyString)

  const terrasIndigenasEAldeiasData: IGetHistoryIntersect['data'] = []
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasDominiais?.data, 'intersectTerrasIndigenasDominiais'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasNaoHomologadas?.data, 'intersectTerrasIndigenasNaoHomologadas'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasHomologadas?.data, 'intersectTerrasIndigenasHomologadas'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasReservas?.data, 'intersectTerrasIndigenasReservas'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasSobInterdicao?.data, 'intersectTerrasIndigenasSobInterdicao'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectTerrasIndigenasEmEstudo?.data, 'intersectTerrasIndigenasEmEstudo'))
  terrasIndigenasEAldeiasData.push(...parseIntersectData(parsed.intersectAldeiasIndigenas?.data, 'intersectAldeiasIndigenas'))

  const terrasIndigenasEAldeiasStatus: ResponseStatus = [
    parsed.intersectTerrasIndigenasDominiais?.status,
    parsed.intersectTerrasIndigenasNaoHomologadas?.status,
    parsed.intersectTerrasIndigenasHomologadas?.status,
    parsed.intersectTerrasIndigenasReservas?.status,
    parsed.intersectTerrasIndigenasSobInterdicao?.status,
    parsed.intersectTerrasIndigenasEmEstudo?.status,
    parsed.intersectAldeiasIndigenas?.status
  ].filter(notEmpty).every((status) => status === 'FAILED')
    ? 'FAILED'
    : 'OK'

  const terrasIndigenasEAldeiasReason = [
    parsed.intersectTerrasIndigenasDominiais?.reason,
    parsed.intersectTerrasIndigenasNaoHomologadas?.reason,
    parsed.intersectTerrasIndigenasHomologadas?.reason,
    parsed.intersectTerrasIndigenasReservas?.reason,
    parsed.intersectTerrasIndigenasSobInterdicao?.reason,
    parsed.intersectTerrasIndigenasEmEstudo?.reason,
    parsed.intersectAldeiasIndigenas?.reason
  ].filter(notEmptyString)

  const mappedData = {
    ...parsed,
    alertas: Array.from(alertas.keys()).reduce((obj: { [key: string]: boolean }, key: string) => {
      return {
        ...obj,
        [key]: true
      }
    }, {}),
    dadosImovel: {
      ...parsed.dadosImovel,
      isAlienado: parsed.isAlienado,
      isRegisteredSncr: parsed.isRegisteredSncr,
      descricao: parsed.dadosImovel.descricaoImovel,
      descriptionRaw: parsed.descriptionRaw
    },
    historico: mappedTransactions,
    metadata: {
      googleMaps: parsed.googleMapsMetadata,
      geolocation: parsed.geolocation,
      chosenCoordinates: parsed.chosenCoordinates,
      satelliteImages: (parsed.satelliteImages || []).map(({ image, date, ...item }) => ({
        ...item,
        date: factoryDate(date),
        key: image
      })),
      meteorologicalAnalysis: {
        status: meteorologicalStatus,
        reason: meteorologicalReason,
        data: meteorologicalData
      },
      intersectAreaPreservacaoPermanente: parsed.intersectApp,
      intersectReservaLegal: parsed.intersectRl,
      intersectTerrasIndigenasEAldeias: {
        status: terrasIndigenasEAldeiasStatus,
        reason: terrasIndigenasEAldeiasReason.join('.'),
        data: terrasIndigenasEAldeiasData
      },
      intersectUnidadesDeConservacao: parsed.intersectUnidadesDeConservacao,
      intersectAreaDesmatamento: {
        status: areaDesmatamentoStatus,
        reason: areaDesmatamentoReason.join('.'),
        data: areaDesmatamentoData
      }
    }
  }

  return mappedData
}

interface Response {
  dadosImovel: ItemDadosImovelResponse
  alertas: ResponseAlertas
  certidao?: string
  blocks: Array<ResponseBlocks>
  descriptionRaw?: string
  historicoTransacoes: Array<ItemHistoricoTransacaoResponse>
  isAlienado?: boolean
  googleMapsMetadata?: ResponseGoogleMapsMetadata
  geolocation: ResponseGeolocation
  codigoCar?: string
  chosenCoordinates?: ResponseChosenCoordinates
  chosenCoordinatesMethod?: string
  isRegisteredSncr?: boolean
  intersectApp: ResponseIntersect
  intersectRl: ResponseIntersect
  intersectTerrasIndigenasDominiais?: ResponseIntersect
  intersectTerrasIndigenasNaoHomologadas?: ResponseIntersect
  intersectTerrasIndigenasHomologadas?: ResponseIntersect
  intersectTerrasIndigenasReservas?: ResponseIntersect
  intersectTerrasIndigenasSobInterdicao?: ResponseIntersect
  intersectTerrasIndigenasEmEstudo?: ResponseIntersect
  intersectAldeiasIndigenas?: ResponseIntersect
  intersectUnidadesDeConservacao?: ResponseIntersect
  intersectAmazoniaLegal?: ResponseIntersect
  intersectAmazonia: ResponseIntersect
  intersectCaatinga: ResponseIntersect
  intersectCerrado: ResponseIntersect
  intersectMataAtlantica: ResponseIntersect
  intersectPampa: ResponseIntersect
  intersectPantanal: ResponseIntersect
  meteorologicalAnalysis?: ResponseMeteorologicalAnalysis
  satelliteImages?: Array<ResponseSatelliteImageItem>
}

interface ItemDadosImovelResponse {
  codigoImo?: string
  denominacao?: string
  tipoImovel?: string,
  cartorio?: string,
  comarca?: string
  codigoCns?: string,
  numeroMatricula?: string,
  dataAbertura?: string,
  matriculaAnterior?: Array<string>,
  descricaoImovel?: string,
  confrontacoesDoTerreno?: string,
  descricaoDaEdificacao?: string,
  confrontacoesDaEdificacao?: string,
  areaUtil?: string,
  areaComum?: string,
  areaGaragem?: string,
  areaTerreno?: string,
  vagasGaragem?: string,
  endereco?: {
    logradouro?: string,
    numero?: string,
    complemento?: string,
    bairro?: string,
    cidade?: string,
    uf?: string,
    cep?: string,
    zona?: string,
    quadra?: string,
    lote?: string,
  },
  proprietarios?: ResponseProprietario[]
}

interface ResponseProprietario {
  nome: string
  documento: string
}

interface ResponseAlertas {
  remissaoDeForo: boolean,
  tombamentoDeBemImovel: boolean,
  levantamentoDeSequestro: boolean,
  registroTorrens: boolean,
  concessaoDePatrimonioDeAfetacaoOuRegimeDeAfetacao: boolean,
  leiloesNegativos: boolean,
  regimeDeAforamento: boolean,
  usufruto: boolean,
  inalienabilidade: boolean,
  incomunicabilidade: boolean,
  impenhorabilidade: boolean,
  imovelPenhorado: boolean,
  imovelArrematadoPorLeilao: boolean,
  encargos: boolean,
  areaContaminada: boolean,
  areaDeRecreio: boolean,
  indisponibilidade: boolean,
  servidaoDePassagem: boolean,
  renunciaDeUsufruto: boolean,
}

interface ResponseBlocks {
  page: number,
  blocks: Array<{
    id: string,
    polygon: Array<number>
    text: string,
    label: string,
  }>
}

interface ItemHistoricoTransacaoResponse {
  id: number,
  refIds: string[],
  tipoTransacao?: string,
  data?: string,
  valor?: string,
  unidadeMonetaria?: string,
  tipoGrupo?: string,
  origem?: number,
  cancelamento?: number,
  dataVencimento?: string,
  grauHipoteca?: string,
  adquirenteExequente: Array<{
    doc?: string,
    nome?: string,
  }>,
  vendedorExecutado: Array<{
    doc?: string
    nome?: string,
  }>,
  unknownPeople: Array<{
    doc?: string
    nome?: string,
  }>
}

interface ResponseGoogleMapsMetadata {
  address?: string,
  geolocation?: null | {
    lat: number,
    lng: number
  }
  images?: Array<{
    status: string,
    link: string,
  }>,
}

type ResponseStatus = 'OK' | 'FAILED'

interface ResponseGeolocation {
  status: ResponseStatus
  reason: string
  data: {
    lat: number
    lng: number
  }
}

interface ResponseChosenCoordinates {
  status: ResponseStatus
  reason: string
  data: {
    geoJson: ResponseGeoJson
  }
}

interface ResponseGeoJson {
  coordinates: number[][][]
  type: string
  geometries: any
}

interface ResponseIntersect {
  status: ResponseStatus
  reason: string
  data: Array<{
    desc: string
    id: string
    geoJson: Omit<ResponseGeoJson, 'geometries'>
  }>
}

interface ResponseMeteorologicalAnalysis {
  lst: {
    status: ResponseStatus
    reason: string
    data: Array<{
      datetime: number
      lstMonth1Km?: number
      lstMonth1KmFitted?: number
    }>
  },
  spei: {
    status: ResponseStatus
    reason: string
    data: Array<{
      datetime: number
      spei01Month?: number
      spei01MonthFitted?: number
    }>
  },
  ecmwf: {
    status: ResponseStatus
    reason: string
    data: Array<{
      datetime: number
      totalPrecipitationSum?: number
      totalPrecipitationSumFitted?: number
    }>
  },
}

interface ResponseSatelliteImageItem {
  status: ResponseStatus
  err?: string
  date: string
  image?: string
}
