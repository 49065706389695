import React, { Fragment, useRef } from 'react'
import { Box, Button, Divider, Grid, List, ListItem } from '@mui/material'

import {
  FaFile,
  FaFileAlt,
  FaFileImage,
  FaFileInvoice,
  FaFilePdf,
  FaUpload
} from 'react-icons/fa'
import { IconBaseProps } from 'react-icons'
import CancelIcon from '@mui/icons-material/Cancel'

import { readFile } from '../../lib/images'
import { IFileItem } from '../../services/api/matricula'

import styles from './style.module.scss'

interface FilePickerSingleProps {
  onChange: (file: IFileItem | null) => void
  onError: (message: string) => void
  data?: IFileItem | null
  multiple?: false
  className?: string
  fileType?: string
  height?: number
  width?: number
}

interface FilePickerMultipleProps {
  onChange: (file: IFileItem[]) => void
  onError: (message: string) => void
  data?: IFileItem[]
  multiple: true
  className?: string
  fileType?: string
}
export type FilePickerProps = FilePickerSingleProps | FilePickerMultipleProps
export default function FilePicker ({
  onChange,
  onError,
  data,
  multiple,
  className,
  fileType: fileTypeProps
}: FilePickerProps) {
  const fileType = fileTypeProps ?? '*'
  const ref = useRef<HTMLInputElement | null>(null)

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (!files) {
      if (!multiple) {
        return null
      } else {
        return []
      }
    }

    if (!multiple) {
      const file = files.item(0)
      if (!file) {
        return null
      }
      const encodedFile = await readFile(file)
      return { name: file.name, content: encodedFile, type: file.type, _raw: file }
    } else {
      const array = []
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        if (!file) {
          continue
        }
        const encodedFile = await readFile(file)
        array.push({ name: file.name, content: encodedFile, type: file.type, _raw: file })
      }

      const prevData = (data || []) as IFileItem[]

      return [...prevData, ...array]
    }
  }

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    if (ref.current) {
      ref.current.click()
    }
  }

  const handleRemoveFile = (index: number) => {
    if (onChange) {
      if (multiple) {
        const newData = (data || []).filter((_, i) => i !== index)
        onChange(newData)
      } else {
        onChange(null)
      }
    }
  }

  const containerClass = styles.container

  const renderInput = () => {
    return (
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', m: 4 }}
      >
        <FaUpload size={30} className='mb-3' />
        <Button
          type='button'
          variant='text'
          sx={{ mt: 2 }}
          onClick={handleClick}
        >
          Selecione {multiple ? 'os arquivos' : 'o arquivo'}
        </Button>
      </Box>
    )
  }

  const renderFiles = () => {
    const arrayData: IFileItem[] = Array.isArray(data) ? data : ([data].filter(item => !!item)) as IFileItem[]

    const resolveType = ({ type, extension }: { type: string, extension: string }) => {
      if (type.startsWith('image/')) {
        return 'image'
      } else if (type === 'application/pdf' || extension === 'pdf') {
        return 'pdf'
      } else if (
        [
          'cpe',
          'cpr',
          'rem',
          'ret',
          '2pe',
          '2re',
          '2pr',
          '2rr',
          'pag'
        ].includes(extension)
      ) {
        return 'invoice'
      } else if (type === 'text/plain' || extension === 'txt') {
        return 'txt'
      } else {
        return 'unknown'
      }
    }

    const Icon = ({ type, ...rest }: IconBaseProps & { type: string }) => {
      let InternalIcon = null
      switch (type) {
        case 'image':
          InternalIcon = FaFileImage
          break
        case 'pdf':
          InternalIcon = FaFilePdf
          break
        case 'txt':
          InternalIcon = FaFileAlt
          break
        case 'invoice':
          InternalIcon = FaFileInvoice
          break
        default:
          InternalIcon = FaFile
          break
      }
      return <InternalIcon {...rest} />
    }
    return (
      <List sx={{ my: 1 }}>
        {arrayData.map(({ name, type }, index) => {
          const extension = name.split('.').pop() ?? ''
          return (
            <Fragment key={index.toString()}>
              <Divider variant='fullWidth' component='li' />
              <ListItem sx={{ my: 1 }}>
              <Grid container direction='row'>
                  <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Icon size={25} type={resolveType({ type, extension })} />
                  </Grid>
                  <Grid item xs={8}>
                    {name}
                  </Grid>
                  <Grid item xs={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CancelIcon
                      fontSize='small'
                      color='error'
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleRemoveFile(index)}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </Fragment>
          )
        })}
      </List>
    )
  }

  return (
    <div
      className={
        `rounded bg-light text-primary ${containerClass} ` + (className || '')
      }
      onClick={data ? undefined : handleClick}
    >
      {renderInput()}
      {data && renderFiles()}
      <input
        type='file'
        multiple={multiple}
        ref={ref}
        style={{ display: 'none' }}
        accept={fileType}
        onChange={async event => {
          try {
            const fileData = await handleFile(event)
            if (multiple) {
              onChange(fileData as IFileItem[])
            } else {
              onChange(fileData as IFileItem)
            }
          } catch (err) {
            console.error(err)
            onError((err as Error).message || 'Erro ao carregar arquivo')
          }
        }}
      />
    </div>
  )
}
