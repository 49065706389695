import { consultarImagens } from './googleMaps'
import {
  parseMatricula,
  GetHistoryProps as GetHistoryPropsMatricula,
  getHistory as getHistoryMatricula
} from './matricula'
import {
  parseContrato,
  getHistory as getHistoryContrato
} from './contrato'
import {
  parseFaturamento,
  getHistory as getHistoryFaturamento
} from './faturamento'
import {
  parsePeticaoInicial,
  getHistory as getHistoryPeticaoInicial
} from './peticaoInicial'
import { getDocument, listDocuments } from './documento'

import type { GetDocumentProps, IListDocumentItem } from './documento'
import { generateMapLayer, toGeojson } from './propriedade'
import { getApf, downloadApf, getCcir, downloadCcir, getEmbargosSema, downloadEmbargosSema, getItr, downloadItr, downloadSimcar, getSimcar } from './certidoes'

export { FUNCTIONS_NAME } from './matricula'

export type {
  ConsultarImagensProps
} from './googleMaps'

export type {
  IListDocumentItem
} from './documento'

export type {
  IGetHistory as IGetHistoryMatricula,
  IGetHistoryAlertas as IGetHistoryAlertasMatricula,
  IGetHistoryGoogleMapsMetadata as IGetHistoryGoogleMapsMetadataMatricula,
  IGetHistoryDadosImovel,
  IGetHistoryItem as IGetHistoryItemMatricula,
  IFileItem,
  GetHistoryProps as GetHistoryPropsMatricula,
  IGetHistorySigef as IGetHistorySigefMatricula,
  IGetHistoryChosenCoordinates as IGetHistoryChosenCoordinatesMatricula,
  IGetHistoryIntersect as IGetHistoryIntersectMatricula,
  IGetHistoryMeteorologicalAnalysisItem as IGetHistoryMeteorologicalAnalysisItemMatricula
} from './matricula'

export type {
  GetHistoryProps as GetHistoryPropsContrato,
  IGetHistory as IGetHistoryContrato
} from './contrato'

export type {
  GetHistoryProps as GetHistoryPropsFaturamento,
  IGetHistory as IGetHistoryFaturamento
} from './faturamento'

export type {
  GetHistoryProps as GetHistoryPropsPeticaoInicial,
  IGetHistory as IGetHistoryPeticaoInicial
} from './peticaoInicial'

export type {
  ToGeojsonInput as GetPropriedadeInput
} from './propriedade'

export type {
  GetApfInput,
  DownloadApfInput,
  GetCcirInput,
  DownloadCcirInput,
  CcirItem,
  ApfItem,
  GetEmbargosSemaInput,
  GetEmbargosSemaOutput,
  GetItrInput,
  GetItrOutput,
  ItrItem,
  GetSimcarInput,
  GetSimcarOutput,
  SimcarItem,
  EmbargosSemaItem
} from './certidoes'

const API = {
  matricula: {
    history: {
      get: (params: GetHistoryPropsMatricula) => getHistoryMatricula({ ...params, version: 'v1' })
    },
    list: async (): Promise<Array<Omit<IListDocumentItem, 'propertyType' | 'googleMapsStatus'>>> => listDocuments({ type: 'matricula' }),
    get: async (params: Omit<GetDocumentProps, 'type'>) => getDocument({ ...params, type: 'matricula' }).then(data => parseMatricula(data as any))
  },
  matriculaV2: {
    history: {
      get: (params: GetHistoryPropsMatricula) => getHistoryMatricula({ ...params, version: 'v2' })
    },
    list: async () => listDocuments({ type: 'matriculaV2' }),
    get: async (params: Omit<GetDocumentProps, 'type'>) => getDocument({ ...params, type: 'matriculaV2' }).then(data => parseMatricula(data as any))
  },
  contrato: {
    history: {
      get: getHistoryContrato
    },
    list: async (): Promise<Array<Omit<IListDocumentItem, 'propertyType' | 'googleMapsStatus'>>> => listDocuments({ type: 'contrato' }),
    get: async (params: Omit<GetDocumentProps, 'type'>) => getDocument({ ...params, type: 'contrato' }).then(data => parseContrato(data as any))
  },
  faturamento: {
    history: {
      get: getHistoryFaturamento
    },
    list: async (): Promise<Array<Omit<IListDocumentItem, 'propertyType' | 'googleMapsStatus'>>> => listDocuments({ type: 'faturamento' }),
    get: async (params: Omit<GetDocumentProps, 'type'>) => getDocument({ ...params, type: 'faturamento' }).then(data => parseFaturamento(data as any))
  },
  peticaoInicial: {
    history: {
      get: getHistoryPeticaoInicial
    },
    list: async (): Promise<Array<Omit<IListDocumentItem, 'propertyType' | 'googleMapsStatus'>>> => listDocuments({ type: 'peticaoInicial' }),
    get: async (params: Omit<GetDocumentProps, 'type'>) => getDocument({ ...params, type: 'peticaoInicial' }).then(data => parsePeticaoInicial(data as any))
  },
  googleMaps: {
    get: consultarImagens
  },
  propriedade: {
    getPropriedade: toGeojson,
    getAreasAtencao: generateMapLayer
  },
  certidoes: {
    apf: {
      get: getApf,
      download: downloadApf
    },
    ccir: {
      get: getCcir,
      download: downloadCcir
    },
    embargosSema: {
      get: getEmbargosSema,
      download: downloadEmbargosSema
    },
    itr: {
      get: getItr,
      download: downloadItr
    },
    simcar: {
      get: getSimcar,
      download: downloadSimcar
    }
  }
}

export default API
