import { formatCNPJ, formatCPF } from '@brazilian-utils/brazilian-utils'
import camelcaseKeys from 'camelcase-keys'

export const normalize = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

type formatCurrencyProps = (
  number: number,
  lang?: 'pt-BR' | 'en-US' | null,
  currency?: string | null,
  options?: Intl.NumberFormatOptions & {
    fractionDigits?: number
  }
) => string

export const formatCurrency: formatCurrencyProps = (
  number: number,
  lang,
  currency,
  options
) => {
  const internalLang = lang || 'pt-BR'
  const internalCurrency = currency || 'BRL'
  const internalOptions = options || {
    fractionDigits: 2
  }

  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'currency',
    currency: internalCurrency,
    minimumFractionDigits: internalOptions.fractionDigits,
    maximumFractionDigits: internalOptions.fractionDigits,
    ...internalOptions
  })

  return formatter.format(number)
}

type formatPercentProps = (
  number: number,
  lang?: 'pt-BR' | 'en-US' | null,
  options?: Intl.NumberFormatOptions & {
    fractionDigits?: number
  }
) => string
export const formatPercent:formatPercentProps = (
  number,
  lang,
  options
) => {
  const internalLang = lang || 'pt-BR'
  const internalOptions = options || {
    fractionDigits: 2
  }
  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'percent',
    minimumFractionDigits: internalOptions.fractionDigits,
    maximumFractionDigits: internalOptions.fractionDigits,
    ...internalOptions
  })

  return formatter.format(number)
}

type formatNumberProps = (
  number: number,
  lang?: 'pt-BR' | 'en-US' | null,
  options?: Intl.NumberFormatOptions & {
    fractionDigits?: number
  }
) => string
export const formatDecimal: formatNumberProps = (
  number,
  lang,
  options
) => {
  const internalLang = lang || 'pt-BR'
  const internalOptions = options || {
    fractionDigits: 2
  }
  const formatter = new Intl.NumberFormat(internalLang, {
    style: 'decimal',
    minimumFractionDigits: internalOptions?.fractionDigits,
    maximumFractionDigits: internalOptions?.fractionDigits,
    ...internalOptions
  })

  return formatter.format(number)
}

export const firstLetterUpperCase = (word: string) => {
  const firstLetter = word.charAt(0).toUpperCase()
  const otherLetters = word.slice(1).toLowerCase()

  return firstLetter + otherLetters
}

export const capitalize = (value: string) => {
  return value
    .split(' ')
    .map(word => firstLetterUpperCase(word))
    .join(' ')
}

export const toCamelCase = <T>(value: string | object | Array<unknown>): string | object | Array<unknown> => {
  if (typeof value === 'string') {
    return Object.keys(camelcaseKeys({ [value]: '' }))[0] as string
  } else if (Array.isArray(value)) {
    return camelcaseKeys(value, { deep: true }) as Array<T>
  } else {
    return camelcaseKeys(value, { deep: true }) as object
  }
}

export const formatBoolean = (value: boolean) => {
  return value ? 'sim' : 'não'
}

export const formatDocument = (document: string): string => {
  const cleanDocument = document.replace(/\D/g, '')

  if (cleanDocument.length === 11) {
    return formatCPF(cleanDocument)
  }

  if (cleanDocument.length === 14) {
    return formatCNPJ(cleanDocument)
  }

  return cleanDocument
}
