import { Auth } from 'aws-amplify'

import { SCOPE } from '../../config/env'

export const getIdToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.idToken.jwtToken as string
}

export const getAccessToken = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()

  return currentUser.signInUserSession.accessToken.jwtToken as string
}

export const getUserData = async () => {
  const currentUser = await Auth.currentAuthenticatedUser()
  const email = currentUser.signInUserSession.idToken.payload.email as string
  const username = currentUser.signInUserSession.idToken.payload['cognito:username'] as string

  const tenant = SCOPE
  const groups: string[] =
    currentUser.signInUserSession.idToken.payload['cognito:groups'] || []
  const scope = groups.some(item => item.includes('indrema'))
    ? 'indrema'
    : SCOPE

  return {
    email,
    username,
    tenant,
    groups,
    scope
  }
}
