
export const readFile = async file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.onload = event => resolve(event.target.result)
    fileReader.onabort = event => reject(event)
    fileReader.onerror = event => reject(event)
    fileReader.readAsDataURL(file)
  })
}

export const base64ToBlob = async base64Data => {
  const base64Response = await fetch(base64Data)
  const blob = await base64Response.blob()
  return blob
}
