import { BUCKET_NAME } from '../../config/consts'
import { STAGE } from '../../config/env'

import { StaticLambdaClient } from '../../lib/lambda'
import { StaticS3Client } from '../../lib/s3'

import { formatWithTimezone, parseWithTimezone } from '../../utils/date'
import { capitalize, toCamelCase } from '../../utils/formatter'

import { getIdToken } from '../aws'

const FUNCTIONS_NAME = {
  GET_APF: `GetAPF-CertidoesRurais-Function-${capitalize(STAGE)}`,
  GET_CCIR: `GetCCIR-CertidoesRurais-Function-${capitalize(STAGE)}`,
  GET_EMBARGOS_SEMA: `GetEmbargosSema-CertidoesRurais-Function-${capitalize(STAGE)}`,
  GET_ITR: `RunITRContainer-CertidoesRurais-Function-${capitalize(STAGE)}`,
  GET_SIMCAR: `GetSimcar-CertidoesRurais-Function-${capitalize(STAGE)}`
} as const

export type GetApfInput = {
  document: string
}

export type GetApfOutput = Array<ApfItem>

export type ApfItem = {
  document: string
  autorizacao: string
  status: string
  imovel: string
  car: string
  responsavel: string
  atividade: string
  municipio: string
  dataEmissao: string
  dataValidade: string
  ultimaAtualizacao: string
  eventTermo: string
  eventApf: string
}

export const getApf = async ({ document }: GetApfInput): Promise<GetApfOutput> => {
  const cleanDocument = document.replace(/\D/g, '')

  const idToken = await getIdToken()
  const client = await StaticLambdaClient.getInstance({
    idToken,
    lambdaRegion: 'sa-east-1'
  })

  const rawResponse = await client.invokeFunction({
    functionName: FUNCTIONS_NAME.GET_APF,
    body: {
      doc_proprietario: cleanDocument
    }
  })

  type Response = Array<{
    autorizacao: string
    status: string
    imovel: string
    car: string
    responsavel: string
    atividade: string
    municipio: string
    dataEmissao: string
    dataValidade: string
    ultimaAtualizacao: string
    eventTermo: string
    eventApf: string
  }>

  const formattedData = toCamelCase(rawResponse) as Response

  return formattedData.map(item => ({
    ...item,
    document: cleanDocument
  })).sort((a, b) => {
    const aDate = parseWithTimezone(a.ultimaAtualizacao, 'dd/MM/yyyy')
    const bDate = parseWithTimezone(b.ultimaAtualizacao, 'dd/MM/yyyy')

    if (aDate.getTime() !== bDate.getTime()) {
      return bDate.getTime() - aDate.getTime()
    }

    const aStatus = a.status.toUpperCase().trim()
    const bStatus = b.status.toUpperCase().trim()

    if (aStatus !== bStatus) {
      return aStatus === 'REGULAR' ? -1 : 1
    }

    return 0
  })
}

export type DownloadApfInput = {
  document: string
  autorizacao: string
}

export const downloadApf = async ({ document, autorizacao }: DownloadApfInput): Promise<string> => {
  const idToken = await getIdToken()
  const client = await StaticS3Client.getInstance({ idToken })

  const cleanDocument = document.replace(/\D/g, '')
  const cleanAutorizacao = autorizacao.replace(/\D/g, '')

  const fileName = `apf_${cleanDocument}_${cleanAutorizacao}.pdf`
  const key = `stage=${STAGE.toLowerCase()}/resource=certidoes/type=apf/doc_proprietario=${cleanDocument}/${cleanAutorizacao}.pdf`

  const url = await client.getPresignedUrl({
    filename: fileName,
    bucket: BUCKET_NAME,
    key,
    expires: 60 * 10 // 10 minutes
  })

  return url
}

export type GetCcirInput = {
  codigoImovel: string
  uf: string
  municipio: string
  cpf: string
}

export type GetCcirOutput = Array<CcirItem>

export type CcirItem = {
  codigoImovelRural: string
  denominacaoImovelRural: string
  municipioSede: string
  ufSede: string
  numeroCcir: string
  dataLancamento: string
  dataEmissao: string
  dataVencimento: string
  debitosAnteriores: string
  taxasServicosCadastrais: string
  valorCobrado: string
  multa: string
  juros: string
  valorTotal: string
  situacaoPagamento: string
  situacaoCcir: string
}

export const getCcir = async ({
  cpf, codigoImovel,
  uf,
  municipio
}: GetCcirInput): Promise<GetCcirOutput> => {
  const cleanCpf = cpf.replace(/\D/g, '')
  const cleanUf = uf.trim().toUpperCase()
  const cleanMunicipio = municipio.trim().toUpperCase()

  const idToken = await getIdToken()
  const client = await StaticLambdaClient.getInstance({
    idToken,
    lambdaRegion: 'sa-east-1'
  })

  const rawResponse = await client.invokeFunction({
    functionName: FUNCTIONS_NAME.GET_CCIR,
    body: {
      codigo_imovel: codigoImovel,
      uf: cleanUf,
      mun: cleanMunicipio,
      cpf_proprietario: cleanCpf
    }
  })

  type Response = {
    codigoImovelRural: string
    denominacaoImovelRural: string
    municipioSede: string
    ufSede: string
    numeroCcir: string
    dataLancamento: string
    dataEmissao: string
    dataVencimento: string
    debitosAnteriores: string
    taxasServicosCadastrais: string
    valorCobrado: string
    multa: string
    juros: string
    valorTotal: string
    situacaoPagamento: string
    situacaoCcir: string
  }

  const formattedData = [toCamelCase(rawResponse) as Response]
  return formattedData.map(item => ({
    ...item,
    cpf: cleanCpf
  })).sort((a, b) => {
    const aDate = parseWithTimezone(a.dataEmissao, 'dd/MM/yyyy')
    const bDate = parseWithTimezone(b.dataEmissao, 'dd/MM/yyyy')

    if (aDate.getTime() !== bDate.getTime()) {
      return bDate.getTime() - aDate.getTime()
    }

    return 0
  })
}

export type DownloadCcirInput = {
  codigoImovel: string
}

export const downloadCcir = async ({ codigoImovel }: DownloadCcirInput): Promise<string> => {
  const idToken = await getIdToken()
  const client = await StaticS3Client.getInstance({ idToken })

  const cleanCodigoImovel = codigoImovel.replace(/\D/g, '')

  const fileName = `ccir_${cleanCodigoImovel}.pdf`
  const key = `stage=${STAGE.toLowerCase()}/resource=certidoes/type=ccir/codigo_imovel=${cleanCodigoImovel}/result.pdf`

  const url = await client.getPresignedUrl({
    filename: fileName,
    bucket: BUCKET_NAME,
    key,
    expires: 60 * 10 // 10 minutes
  })

  return url
}

export type GetEmbargosSemaInput = {
  document: string
}

export type GetEmbargosSemaOutput = Array<EmbargosSemaItem>

export interface EmbargosSemaItem {
  numero: string
  modelo: string
  processo: string
  empreendimento: string
  municipio: string
  document: string
}

export const getEmbargosSema = async ({ document }: GetEmbargosSemaInput): Promise<GetEmbargosSemaOutput> => {
  const cleanDocument = document.replace(/\D/g, '')

  const idToken = await getIdToken()
  const client = await StaticLambdaClient.getInstance({
    idToken,
    lambdaRegion: 'sa-east-1'
  })

  const rawResponse = await client.invokeFunction({
    functionName: FUNCTIONS_NAME.GET_EMBARGOS_SEMA,
    body: {
      doc_proprietario: cleanDocument
    }
  })

  type Response = Array<{
    numero: string
    modelo: string
    processo: string
    empreendimento: string
    municipio: string
  }>

  const formattedData = toCamelCase(rawResponse) as Response

  return formattedData.map(item => ({
    ...item,
    empreendimento: item.empreendimento.trim(),
    document: cleanDocument
  }))
}

export type DownloadEmbargosSemaInput = {
  document: string
}

export const downloadEmbargosSema = async ({ document }: DownloadEmbargosSemaInput): Promise<string> => {
  const idToken = await getIdToken()
  const client = await StaticS3Client.getInstance({ idToken })

  const cleanDocument = document.replace(/\D/g, '')

  const fileName = `embargos-sema_${cleanDocument}.pdf`
  const key = `stage=${STAGE.toLowerCase()}/resource=certidoes/type=embargos_sema/doc_proprietario=${cleanDocument}/embargos.pdf`

  const url = await client.getPresignedUrl({
    filename: fileName,
    bucket: BUCKET_NAME,
    key,
    expires: 60 * 10 // 10 minutes
  })

  return url
}

export type GetItrInput = {
  nirf: string
}

export type GetItrOutput = Array<ItrItem>

export interface ItrItem {
  nirf: string
  codigoControle: string
  tipo: string
  dataEmissao: string
  dataValidade: string,
  situacao: string,
  linkSegundaVia: string
}

export const getItr = async ({ nirf }: GetItrInput): Promise<GetItrOutput> => {
  const cleanNirf = nirf.replace(/\D/g, '')

  const idToken = await getIdToken()
  const client = await StaticLambdaClient.getInstance({
    idToken,
    lambdaRegion: 'sa-east-1'
  })

  const rawResponse = await client.invokeFunction({
    functionName: FUNCTIONS_NAME.GET_ITR,
    body: {
      nirf: cleanNirf
    }
  })

  type Response = Array<{
    codigoControle: string
    tipo: string
    dataEmissao: string
    dataValidade: string,
    situacao: string,
    linkSegundaVia: string
  }>

  const formattedData = toCamelCase(rawResponse) as Response

  return formattedData.map(item => ({
    ...item,
    nirf: cleanNirf
  })).sort((a, b) => {
    const aDate = parseWithTimezone(a.dataEmissao, 'dd/MM/yyyy HH:mm:ss')
    const bDate = parseWithTimezone(b.dataEmissao, 'dd/MM/yyyy HH:mm:ss')

    if (aDate.getTime() !== bDate.getTime()) {
      return bDate.getTime() - aDate.getTime()
    }

    return 0
  })
}

export type DownloadItrInput = {
  nirf: string
  codigoControle: string
}

export const downloadItr = async ({ nirf, codigoControle }: DownloadItrInput): Promise<string> => {
  const idToken = await getIdToken()
  const client = await StaticS3Client.getInstance({ idToken })

  const cleanNirf = nirf.replace(/\D/g, '')

  const fileName = `itr_${cleanNirf}_${codigoControle}.pdf`
  const key = `stage=${STAGE.toLowerCase()}/resource=certidoes/type=itr/nirf=${cleanNirf}/${codigoControle}.pdf`

  const url = await client.getPresignedUrl({
    filename: fileName,
    bucket: BUCKET_NAME,
    key,
    expires: 60 * 10 // 10 minutes
  })

  return url
}

export type GetSimcarInput = {
  car: string
}

export type GetSimcarOutput = Array<SimcarItem>

export interface SimcarItem {
  car: string
  id: string
  rid: string
  numeroCompleto: string
  situacao: string
  propriedadeNome: string
  municipioTexto: string
  dataUltimoEnvio: string
  ultimoEnvioFormatted: string
  situacaoCompleta: string
}

export const getSimcar = async ({ car }: GetSimcarInput): Promise<GetSimcarOutput> => {
  const idToken = await getIdToken()
  const client = await StaticLambdaClient.getInstance({
    idToken,
    lambdaRegion: 'sa-east-1'
  })

  const rawResponse = await client.invokeFunction({
    functionName: FUNCTIONS_NAME.GET_SIMCAR,
    body: {
      numero_car: car
    }
  })

  type Response = {
    quantidadeTotal: number
    itens:
    Array<{
      id: number
      rId: number
      numeroCompleto: string
      situacao: string
      propriedadeNome: string
      municipioTexto: string
      dataUltimoEnvio: string
      situacaoCompleta: string
    }>
  }

  const formattedData = toCamelCase(rawResponse) as Response

  return formattedData.itens.map(item => ({
    ...item,
    id: item.id.toString(),
    rid: item.rId.toString(),
    ultimoEnvioFormatted: formatWithTimezone(parseWithTimezone(item.dataUltimoEnvio, 'yyyy-MM-dd\'T\'HH:mm:ss'), 'dd/MM/yyyy HH:mm:ss'),
    car
  })).sort((a, b) => {
    const aDate = parseWithTimezone(a.dataUltimoEnvio, 'yyyy-MM-dd\'T\'HH:mm:ss')
    const bDate = parseWithTimezone(b.dataUltimoEnvio, 'yyyy-MM-dd\'T\'HH:mm:ss')

    if (aDate.getTime() !== bDate.getTime()) {
      return bDate.getTime() - aDate.getTime()
    }

    return 0
  })
}

export type DownloadSimcarInput = {
  car: string
  id: string
}

export const downloadSimcar = async ({ car, id }: DownloadSimcarInput): Promise<string> => {
  const idToken = await getIdToken()
  const client = await StaticS3Client.getInstance({ idToken })

  const fileName = `simcar_${car}_${id}.pdf`
  const key = `stage=${STAGE.toLowerCase()}/resource=certidoes/type=simcar/numero_car=${car}/${id}.pdf`

  const url = await client.getPresignedUrl({
    filename: fileName,
    bucket: BUCKET_NAME,
    key,
    expires: 60 * 10 // 10 minutes
  })

  return url
}
