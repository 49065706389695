import React, { useMemo } from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import { formatDistanceStrict, subSeconds } from 'date-fns'
import ptBr from 'date-fns/locale/pt-BR'

import MUIDataTable, { MUIDataTableProps } from 'mui-datatables'
import { Chip, ChipProps, Link } from '@mui/material'

import { IListDocumentItem } from '../services/api'

export type TableItem = IListDocumentItem

export interface TableProps {
  data: TableItem[]
  prefixPath: string
}

export default function Table ({ data, prefixPath }: TableProps) {
  const columns: MUIDataTableProps['columns'] = useMemo(() => {
    return [
      {
        name: 'originalFileName',
        label: 'Arquivo',
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const item = data[dataIndex]
            return item.status === 'processed' ? <Link to={`${prefixPath}/${item.fname}`} component={LinkRouter}>{item.originalFileName}</Link> : item.originalFileName
          }
        }
      },
      {
        name: 'status',
        label: 'Status',
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const item = data[dataIndex]

            const statusDict: { [key: string]: { label: string, color: ChipProps['color'] } } = {
              processed: { label: 'processado', color: 'success' },
              pending: { label: 'pendente', color: 'info' },
              failed: { label: 'falhou', color: 'error' }
            }

            const chipValue = statusDict[item.status]

            return <Chip label={chipValue.label} color={chipValue.color} size='small' sx={{ minWidth: 100 }} />
          }
        }
      },
      {
        name: 'elapsedTime',
        label: 'Duração',
        options: {
          customBodyRenderLite: (dataIndex: number) => {
            const item = data[dataIndex]
            const elapsedTime = item.elapsedTime

            if (!elapsedTime) {
              return ''
            }

            return formatDistanceStrict(new Date(), subSeconds(new Date(), elapsedTime), {
              locale: ptBr
            })
          }
        }
      }
    ].map(item => ({
      ...item,
      options: {
        ...item.options,
        setCellProps: () => ({ size: 'small' })
      }
    }))
  }, [data, prefixPath])

  const optionsTable: MUIDataTableProps['options'] = useMemo(() => ({
    filterType: 'multiselect',
    elevation: 0,
    selectableRowsHideCheckboxes: true,
    setTableProps: () => ({ size: 'small' }),
    downloadOptions: {
      separator: ';'
    },
    textLabels: {
      body: {
        noMatch: 'Ops, não encontramos nada',
        toolTip: 'Ordenar',
        columnHeaderTooltip: column => `Ordenar por ${column.label}`
      },
      pagination: {
        next: 'Próxima página',
        previous: 'Página anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Baixar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver colunas',
        filterTable: 'Filtrar tabela'
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESETAR'
      },
      viewColumns: {
        title: 'Mostrar colunas',
        titleAria: 'Mostrar/Ocultar colunas da tabela'
      },
      selectedRows: {
        text: 'linha(s) selecionada(s)',
        delete: 'Deletar',
        deleteAria: 'Deletar linhas selecionadas'
      }
    }
  }), [])

  return (
    <MUIDataTable
      title=''
      data={data}
      columns={columns}
      options={optionsTable}
    />
  )
}
