import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Divider, IconButton, List, Toolbar, Typography, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { Auth } from 'aws-amplify'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { useHandleError } from '../../utils/hooks'

import { SCOPE, VERSION } from '../../config/env'
import { PAGES } from '../../config/consts'

import Drawer, { DrawerProps as SuperProps } from './Drawer'

export interface DrawerMenuProps extends SuperProps {
  drawerWidth: number
  onToggle: () => void
}

interface MenuItem {
  text: string
  id: string
  icon: JSX.Element
  url: string
}

const primaryListItems: MenuItem[] = [
  PAGES.MATRICULA,
  PAGES.CONTRATO,
  PAGES.FATURAMENTO,
  PAGES.PROPRIEDADE,
  PAGES.CERTIDOES_RURAIS
].map(item => ({ ...item, text: item.name }))

if (SCOPE !== 'tentoscap') {
  primaryListItems.push({ ...PAGES.PETICAO_INICIAL, text: PAGES.PETICAO_INICIAL.name })
}

const quaternaryListItems: MenuItem[] = [
  {
    text: 'Sair',
    id: 'logout',
    icon: <ExitToAppIcon />,
    url: '/login'
  }
]

export default function DrawerMenu ({ open, drawerWidth, onToggle }: DrawerMenuProps): JSX.Element {
  const location = useLocation()
  const navigate = useNavigate()
  const { handleError } = useHandleError()

  const list = [primaryListItems, quaternaryListItems]
  return (
    <Drawer variant='permanent' open={open} drawerWidth={drawerWidth}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1]
        }}
      >
        <IconButton onClick={onToggle}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component='nav' sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box>
          {list.map((items, index, array) => {
            return [
              <React.Fragment key={`list-${index}`}>
                {items.map((item) => {
                  return (
                    <Parent title={item.text}
                      key={item.id}
                      open={open}
                    >
                      <ListItemButton
                        selected={location.pathname === item.url}
                        onClick={() => {
                          if (item.id === 'logout') {
                            Auth.signOut().catch(handleError)
                          } else {
                            navigate(item.url)
                          }
                        }}
                      >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    </Parent>
                  )
                })}
              </React.Fragment>,
              index + 1 < array.length ? <Divider sx={{ my: 1 }} key={`divider-${index}`} /> : undefined]
          }).flat().filter(Boolean)}
        </Box>
        <ListItemText sx={{ flex: 1, display: 'flex', alignItems: 'end', justifyContent: 'center' }}>
          <Typography variant='caption' display='block' color='grey.800'>
            v{VERSION}
          </Typography>
        </ListItemText>
      </List>
    </Drawer>
  )
}

const Parent = ({ open, children, title }: { open?: boolean, children: JSX.Element, title: string }) => {
  if (open) {
    return (<div>{children}</div>)
  }
  return (<Tooltip title={title}
    placement='right'
  >{children}</Tooltip>)
}
