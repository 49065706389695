import axios from 'axios'

import { GOOGLE_MAPS_BASE_URL } from '../../config/env'

import { toCamelCase } from '../../utils/formatter'

export interface ConsultarImagensProps {
  logradouro?: string,
  numero?: string,
  cidade?: string,
  uf?: string,
  cep?: string,
}

export interface IGetGoogleMaps {
  images: Array<string>
  geolocation?: null | {
    lat: number,
    lng: number
  },
  address?: string
}

export const consultarImagens = async ({
  logradouro,
  numero,
  cidade,
  uf,
  cep
}: ConsultarImagensProps): Promise<IGetGoogleMaps> => {
  const url = `${GOOGLE_MAPS_BASE_URL}/google_maps`

  const { data } = await axios.get(url, {
    params: {
      logradouro,
      numero,
      municipio: cidade,
      uf,
      cep: cep?.replace(/-/g, '')
    }
  })

  const formattedData = toCamelCase(data) as IGetGoogleMaps
  return formattedData
}
